@charset "utf-8";

@import '~www/themes/doctors/common/variables';

/*  common  */
#nav {
    margin: 0 0 0 -8px;
    padding: 0;
    width: 1000px;

}

#container {
    position: relative;
}

body {
    background-color: $ui-kit-bg-gray-0;
    color: #000000;
    font-family: $font-family-regular;
    font-size: 14px;
    margin: 0;
    overflow-x: hidden
}

p {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0.5em;
}

select, textarea, input[type='text'], input[type="tel"], input[type='password'], input[type='email']  {
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 2px;
}

select {
    background-color: white;
}

input[type='text'], input[type='password'], input[type='email'], input[type="tel"] {
    height: 22px;
    padding-left: 8px;
}

button {
    background-color: #F3F6FA;
    border: 1px solid #999;
    border-radius: 4px;
    height: 22px;
    margin: 0;
    padding: 0 5px;
}

button:hover {
    background-color: #eee;
    cursor: pointer;
}

button.blue {
    background: #0091ff;
    border: 1px solid #0091ff;
    border-radius: 15px;
    margin: 0;
    padding: 0 5px;
    color: white;
    font-weight: 500;
    text-align: center;
    padding: 0 15px;
    font-size: 1.3em;
    line-height: 2em;
    height: 2em;
}

button.blue :hover {
    background: #0078ff;
}

h1 {
    margin-top: 0;
    color: #333;
}

h1 div {
    font-size: 12px;
    font-weight: normal;
}

#content > h1, #content > h2 {
    width: 1000px;
    margin: 0 auto;
}

.description_h1 {
    font-size: 14px;
    display: inline-block;
}

.caption {
    display: block;
    border-bottom: 1px dashed #ccc;
    border-top: 1px dashed #ccc;
    background-color: #F3F6FA;
    font-weight: 500;
    padding: 3px 15px;
    color: gray;
}

.structure {
    width: 95%;
    text-align: center;
    font-weight: 500;
}

.red {
    color: #ee0000;
    font-weight: 500;
}

.red_slim {
    color: #ee0000;
}

.gray, .grey {
    color: gray;
    font-weight: 500;
}

.green {
    color: green;
    font-weight: 500;
}

.nowrap {
    white-space: nowrap;
}

.text_right {
    text-align: right;
}

#header {
    box-shadow: 0 40px 50px 10px rgba(0,0,0,.05);
    clear: both;
    height: auto !important;
    padding: 0;
    background-color: white;
}

#header_in {
    margin: 0 auto;
    height: 60px;
    width: 995px;
    padding-left: 5px;
    position: relative;
}

#header_top {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

/*Это копипаста из блока header-top. Уничтожить, как только все сайты перейдут на блоки.*/
#header_top.header-top_debug {
    background-color: #dcffa5;
}

#header_top_in {
    margin: 0 auto;
    height: 20px;
    width: 1000px;
    position: relative;
    color: black;
}

#header_top_in a {
    color: black;
    line-height: 20px;
    text-decoration: none;
    margin-right: 25px;
}

#header_top_in a.current {
    font-weight: 500;
    color: red;
}

#header_top_in a:hover {
    text-decoration: underline;
}

#header_top .town_select {
    background: url("/static/_v1/pd/icons/pins/pin-black.png") no-repeat left center;
    padding-left: 15px;
    background-size: 14px;
}

.mainpage_header {
    border-bottom: 1px solid #ddd;
}

.user_menu_elem {
    position: absolute;
    top: 5px;
    padding-top: 35px;
    background-size: 32px 32px !important;
    background-position: top center !important;
    text-align: center;
    width: 60px;
    height: 16px;
}

.user_menu_elem:not(.opened):hover {
    cursor: pointer;
}

.user_menu_documents {
    position: absolute;
    left: 500px;
    top: 25px;
}

.user_menu_rates_count {
    position: relative;
    bottom: 10px;
    right: 3px;
    font-weight: 500;
    font-size: 0.8em;
    background-color: #0a0;
    color: white;
    padding: 2px;
    border-radius: 5px;
}

#user_menu_rates a.user_menu_rate_link {
    padding-left: 30px !important;
}

.user_menu_elem > div.prof_menu {
    text-align: left;
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    display: none;
    background-color: white;
    border: 1px solid #CCC;
    box-shadow: 0px 7px 13px #AAA;
}

#user_menu_rates > div ul {
    padding: 0 !important;
    overflow: hidden;
}

#user_menu_rates > div.prof_menu {
    width: 230px;
    padding: 10px 0;
    overflow: hidden;
}

#user_menu_rates > div.prof_menu {
    width: 250px;
}

.user_menu_main ul li {
    padding-left: 20px;
}

#user_menu_logout {
    margin: 10px 0;
}

#user_menu_rates > div a {
    text-decoration: none !important;
    color: black !important;
    display: inline-block;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#user_menu_rates > div a:hover {
    color: black !important;
    background-color: #cce9ff;
}

.personal_manager_avatar {
    margin-left: 10px;
    margin-right: 5px;
    border-radius: 25px;
}

#user_menu_rates.user_menu_link_only {
    width: 90px;
}

.user_menu_link_only > a {
    display: inline-block;
    padding-top: 50px;
    position: relative;
    bottom: 50px;
    text-decoration: none;
}

.user_menu_lpu_settings {
    background: url("/static/_v1/pd/icons/gear.png") no-repeat;
    margin-top: 7px;
}

.user_menu_lpu_page {
    background: url("/static/_v1/pd/icons/arrows/link-arrow-in-square.png") no-repeat;
}

.user_menu_border {
    border-top: 1px solid #CCC;
    margin-top: 10px;
    height: 10px;
}

.user_menu_main {
    position: absolute;
    right: 0;
    width: 215px;
    height: 45px;
    top: 5px;
    /*text-align: right;*/
    box-sizing: border-box;
    font-size: 1.2em;
    background-color: white;
    z-index: 100;
    padding-top: 5px;
}

.user_menu_main.opened {
    width: 240px;
}

.user_menu_main_lpu.opened {
    width: 300px;
}

.user_menu_main_doctor {
    text-align: right !important;
}

.user_menu_main_drugstore.opened {
    height: 130px !important;
}

.user_menu_main > div:not(.user_menu_main_hidden) {
    padding: 0 20px 0 5px;
}

.user_menu_main_hidden {
    overflow: hidden;
    text-align: left;
}

.user_menu_main:not(.opened):hover, .user_menu_elem:not(.opened):hover {
    opacity: 0.5;
}

.user_menu_main:not(.opened) .user_menu_lpu_name, .user_menu_main:not(.opened) .user_menu_doctor_name, .user_menu_main:not(.opened) .user_menu_patient_name {
    /*border-bottom: 1px dotted #ccc;*/
    background: #FFF url("/static/_v1/pd/icons/arrows/drop-down-menu.png") no-repeat scroll right center;
}

.user_menu_patient_name {
    text-align: right;
}

.user_menu_doctor_name, .user_menu_lpu_name {
    text-align: right;
    font-weight: 500;
}

.user_menu_main.opened {
    /*width: 400px;*/
    border: 1px solid #CCC;
    border-radius: 5px;
    height: 200px;
    text-align: left;
    box-shadow: 0px 7px 13px #AAA;
}

.user_menu_main:not(.opened) > div:last-child {
    display: none;
}

.user_menu_main:not(.opened):hover {
    cursor: pointer;
}

.user_menu_main_bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
}

#user_menu_main_bottom a {
    display: inline-block;
    width: 100%;
    border: 0;
    text-decoration: none !important;
    padding: 3px 10px;
    color: black !important;
}

#user_menu_main_bottom div {
    border-top: 1px solid #CCC;
}

#user_menu_main_bottom a:hover {
    color: black !important;
    background-color: #cce9ff;
}

.user_menu_main:not(.opened) .user_menu_lpu_name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.user_menu_town_name {
    font-size: 0.7em;
    text-align: right;
}

.user_menu_email {
    text-align: right;
    font-size: 0.8em;
}

.user_menu_patient_avatar, .user_menu_doctor_avatar {
    vertical-align: middle;
    max-width: 32px;
    border-radius: 16px;
}

.user_menu_doctor_avatar {
    vertical-align: top;
}

.user_menu_doctor_info {
    display: inline-block;
    max-width: 80%;
    padding-right: 0 !important;
}

.user_menu_doctor_info > div {
    padding-right: 20px;
}

.user_menu_appointment_history {
    position: absolute;
    left: 650px;
    top: 10px;
}

.user_line {
    height: 30px;

    margin-top: 7px;
    background-color: #B2E6FF;
    border: 1px solid #aee;
}

.bread_doctor_lpu {
    margin: 0px auto;
    font-size: 1.2em;
    width: 1000px;
    height: 30px;
    line-height: 30px;

}

.bread_doctor_lpu a {
    color: red;
    font-weight: 500;

}

.bread_doctor_lpu span:not(:last-child) {
    display: inline-block;
    padding-right: 40px;
}

.select_prof_lpu {
    width: 300px;
}

.select_prof_lpu .town_name {
    display: block;
    font-size: .7em;
}

#town_change {
    height: auto;
    font-size: 1.3em;
    display: none;
    color: black;
    background-color: rgb(245, 245, 245);
}

#town_change > div {
    margin: 0 auto;
}

#town {
    border: 0;
    color: white;
    font-weight: 500;
    width: 100%;
    min-height: 16px;
    padding-right: 15px;
    background-size: 12px;
    cursor: pointer;
}

.towndiv:hover {
    background-color: #333;
}

.all_lpu_links{
    margin-bottom: 10px;
}
.all_lpu_links a{
    color: #0071ff !important;
    padding-left: 5px;
}

.li_margin {
    margin-bottom: 50px;
}

#bread {
    padding: 7px 0 3px;
    width: 730px;
}

#bread2 {
    line-height: 30px;
    width: auto;
    /*margin: 0 auto;*/
    /*padding-left: 10px;*/
}

#bread2 a {
    color: #025fd4;
    text-decoration: none;
}

#bread2 a:hover {
    color: red;
}

.block_border_top {
    border-top: 1px solid #ccc;
    margin-top: 15px;
}

.block {
    clear: both;
    padding: 0;
    width: 1000px;
    margin: 0 auto;
}
.block_w {
    clear: both;
    padding: 0;
    width: 90%;
    margin: 0 auto;
}

.white_block {
    background: white;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    margin-top: 10px;
}

.rates .block {
    margin-top: 10px;
    background: white;
}

.block_margin {
    margin: 10px 0;
}

.block_head_icon {
    box-sizing: border-box;
    display: none;
    width: 50px;
    height: 50px;
    background-color: white;
    border: 1px solid #ddd;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    float: left;
    margin-left: -50px;
    position: relative;
    left: 1px;
    top: 10px;
}

.block_only_head {
    box-sizing: border-box;
    border-bottom-left-radius: 0;
    padding: 7px;
    margin-bottom: 5px;
}

.block_only_head h2 {
    height: 24px;
    max-width: 830px;
    display: inline-block;
}

.border_top {
    /*border-top: 1px solid #ccc;*/
    padding: 10px 0;
}

.footer_block.border_top {
    border-top: 1px solid #ccc;
}

.block_third {
    width: 33%;
    float: left;
}

.padding_top {
    padding: 10px 0;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.clear {
    clear: both;
}

.clear1px {
    clear: both;
    height: 1px;
}

.left-col {
    width: 48%;
    float: left;
}

.right-col {
    width: 49%;
    float: right;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
    font-weight: 500 !important;
}

.tdleft a {
    text-decoration: none;
}

/*h1 {*/
/* margin-top: 5px; */
/* font-size: 2em; */
/*}*/

a {
    color: black;
}

a:hover {
    color: #EE0000;
}

/* header */

.select2-drop-active {
    border-color: #ccc !important;
}

.apps a {
    font-size: 1.5em !important;
    color: #333;
    display: inline-block;
    border: 0;
    line-height: 28px !important;
}

.apps li.tab {
    border-color: #d7d7d7;
    background-color: #eee;
}

.apps li.tab a {
    line-height: 1em;
    padding-top: 7px;

}

.apps {
    margin: -14px 0 0 0;
    padding: 0;

    margin-left: 245px;
    height: 42px;
}

.apps li.tab {
    text-align: center;
    margin-left: 3px;
    width: 140px;
    margin-top: 6px;

}

.apps li.active {
    background-color: white !important;
    margin-top: 0px !important;

}

.apps span {
    color: grey;
    font-size: 0.9em;
    font-weight: 500;

}

.apps span b {
    color: black;
}

#logo {
    background: 0 0 no-repeat;
    text-decoration: none;
    display: block;
    height: 50px;
    margin: 0;
    width: 300px;
}

.stat {
    float: left;
    margin-left: 70px;
    text-align: right;
}

.week {
    color: gray;
    margin-top: -12px;
    font-size: 0.9em;

}

#stat {
    color: gray;
    height: 40px;
    width: 230px;
}

#stat .left {
    float: left;
    height: 40px;
}

#stat .right {
    color: #ee0000;
    float: right;
    height: 40px;
    text-align: right;
}

/* user menu              */
.user-line {
    float: right;
    position: relative;
    width: 600px;
    text-align: right;
    font-size: 0.9em;
}

.user-line a {
    border-bottom: 1px dotted blue !important;
    color: blue;

}

.user-line a.improve {
    border-bottom: 1px dotted red !important;
    color: red;
    margin-right: 250px;

}

.user-line a:hover {
    border-bottom: 1px solid red !important;
}

.bread_doctor_lpu a.menu-docs {
    color: #dd0000 !important;
    font-size: 1.5em;
}

.bread_doctor_lpu a.menu-docs:hover {
    color: black !important;
}

.user-menu {
    float: right;
    width: 600px;
}

#myavatar {
    margin-left: 930px;
    position: fixed;
    top: 0;
}

.nav-container {
    width: 1000px;

}

.speclist {
    width: 950px;

}

.diagnlist {
    width: 770px;

}

.cnsltlist {
    width: 500px;

}

.diagnlist td > a {
    font-weight: 500;
}

.pharmlist {
    width: 650px;
}

.proflist {
    width: 850px;
}

.dropdown table td, .dropdown2 table td, .prof_menu td {
    vertical-align: top;
}

.dropdown, .dropdown2 {
    position: absolute;
    left: 0;
    top: 100%;
    /*padding: 20px 1000em; *//* trick from css-tricks comments */
    /*margin: 10px -1000em; *//* trick from css-tricks comments */
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    background: #edfcff;
    padding: 10px;
    border: 1px solid #0091ff;
    border-radius: 0 10px 10px 10px;
    margin-top: 10px;

}

.dropdown table, .dropdown2 table {

}

.dropdown a, .dropdown2 a {
    text-decoration: none;
}

.dropdown a:hover, .dropdown2 a:hover {
    text-decoration: underline;
}

.dropdown th {
    font-weight: 500;

}

.dropdown hr {
    margin-right: 50px;

}

.dropdown ul, .prof_menu ul {
    margin: 0;
    padding: 10px;
    list-style: none;
    line-height: 20px;
}

#nav > li:hover {
    visibility: visible;
    opacity: 1;
}

#nav > li {
    float: left;
    position: relative;
    list-style: none;
    font-size: 1.2em;
    background: white;
    display: block;
    border: none;

}

#nav > li > a:hover {
    color: red;

}

#nav > li:hover .dropdown {
    position: absolute !important;
    left: 0;
    top: 26px;
    visibility: visible;
    opacity: 1;
}

#nav > li:hover > a {
    text-decoration: underline;
    border: 1px solid #0091ff;
    border-radius: 5px 5px 0 0;
    border-width: 1px 1px 0;
    position: relative;
    z-index: 99;
    background: #edfcff;
}

#nav > li {
    float: left;
    position: relative;
    list-style: none;
    font-size: 1.2em;
    background: white;
    display: block;

    border: none;
}

/* main level link */
#nav > li > a {
    font-size: 1.3em;
    color: #555;

    display: block;
    padding: 7px 15px;
    margin: 0;
    z-index: 51;
    outline: 0;

    background: none;
    background: url("/static/_v1/pd/icons/arrows/drop-down-menu-sm.png") no-repeat scroll right transparent;
    padding-right: 18px;
}

.lputypes {
    width: 700px;
    height: 200px;

}

.lputype-tbl td, .drugarea-tbl td , .town-tbl td{
    vertical-align: top;
    width: 33%;
}



.lputype-tbl ul, .town-tbl ul, .drugarea-tbl ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.lputype-link{
    font-size: 1.5em;
    color: #0091ff !important;
}

.town-tbl ul li:first-child{
    font-size: 1.5em !important;
}
.town-tbl ul li:first-child a {
    height: 2.2em !important;
    line-height: 2.2em !important;
    box-sizing: border-box ;
    padding-left: 50px !important;
    background-size: 32px 32px;
    background-position:10px;
    color: #0091ff;

}
.town-tbl ul li:first-child .cnt{
    margin-top: -30px;
}


img[itemprop=logo]{
    max-width:200px;
    max-height:200px;
}

.lputype-tbl {
    width: 100%;
}

.lputype-tbl th, .spec-tbl th{
    text-align: left;
    padding-top: 30px;
    font-size: 1.7em;
}



.lputype-tbl a {
    display: block;
    width: 290px;
    padding-right: 30px !important;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 30px;
    margin: 10px 0;
    background-color: white;
    font-size: 1.3em;
    text-decoration: none;
}

.lputype-tbl a:hover {
    border: 1px solid red;
    color: red;
}

.lputype-tbl img {
    width: 50px;
    height: 50px;

}

.lputype-tbl td:first-child a:hover {
    color: #0091ff;
    border-color: #0091ff;
}

.lputype-tbl div {
    margin: 15px 20px;
    float: right;
    color: red;
    font-weight: 500;
    margin-top: -50px;
    font-size: 1.1em;
}

.lputype-tbl td:first-child div {
    color: #0091ff;
}

.lputype-tbl img {
    float: left;
    margin: 5px 15px;
}

.spec-tbl {
    width: 100%;
}

.spec-tbl ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 20px;
    font-size: 1.1em;
}

.spec-tbl td, .town-tbl td {
    vertical-align: top;
}

.spec-tbl a:hover {
    color: #0091ff;
    border-color: #0091ff;
}

.spec-tbl .cnt {
    color: #0091ff;
    font-size: .9em;

}

.town-tbl td a {
    font-size: 1.4em;
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    padding: 5px;
    line-height: 1.2em;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: white;
    margin: 5px 50px 5px 0;
    width: 280px;
    padding: 5px 10px;
}
.town-tbl td li.ser {
    margin-bottom: 15px;
}


.town-tbl .cnt {
    color: #0091ff;
    margin-right: 60px;
    position: relative;
    float: right;
    margin-top: -27px;
}


.town-tbl .cnt2 {
    margin-left: -100px;
    color: #0091ff;
}




.town-tbl td a:hover {
    border-color: #0091ff;
    color: #0091ff;
}

.all_lpus.app_other {
    max-width: 370px;
}

.lputypes ul {
    line-height: 20px;
    list-style: none;
    margin: 10px 20px;
    padding: 0;
    vertical-align: top;
}

.lputypes li {
    margin: 0;
    padding: 0;
    line-height: 20px;
}

.lputypes > ul > div {
    border-bottom: 1px solid #999;
    color: #999;
    margin-bottom: 10px;
}

.consultlist ul {
    width: 100px;
}

#nav .cnt {
    color: #0091ff;
    font-size: 12px;
}

#nav .cnt-red {
    color: red;
    font-size: 12px;
}

.nav_header {
    color: #000000 !important;
    display: inline-block;
    padding-left: 10px;
    width: 100%;
    font-weight: 500 !important;
    /*padding: 8px 0px;*/
}

#nav_rates a, #nav_rates span {
    display: block;
    padding: 5px;
}

#nav_rates a {
    text-decoration: underline;
}

#nav_rates {
    overflow: hidden;
}


.bold {
    font-weight: 500;
}

.delimiter {
    border-top: 1px solid #ccc;
}

#metro {
    padding-left: 30px;
}

.metro-div {
    margin-left: 80px;

}

#metros, #districts {
    width: 217px;
}

.metro_allspec_link {
    color: #0071FF;
    display: inline-block;
    margin-top: 5px;
}

#s2id_lspec {
    position: relative;
    top: 8px;
}

.etabs {
    margin: 0;
    padding: 0;
}

.tab {
    background: #eee;
    border: solid 1px #CCC;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    display: inline-block;
    zoom: 1;
    /**display: inline;*/
}


.tab a {
    display: block;
    font-size: 14px;
    line-height: 2em;
    outline: none;
    padding: 0 10px;
}

.tab a:hover {
    text-decoration: underline;
}

.tab.active {
    background: #fff;
    border-color: #CCC;
    padding-top: 6px;
    position: relative;
    top: 1px;
}

.tab a.active {
    font-weight: 500;
}

.panel-container {
    border: solid #CCC 1px;
    border-radius: 0 4px 4px 4px;
    padding: 10px;
}

.add {
    background: url("/static/_v1/pd/icons/plus/add.png") no-repeat;
    border: 0 !important;
    color: #0071ff;
    font-weight: 500;
    padding: 1px 0 10px 20px;
}

.add:hover {
    background: url("/static/_v1/pd/icons/plus/add-red.png") no-repeat;
}

/* region */
ul.regions {
    padding: 0;
    margin: 0;
}

.regions li {
    list-style: none;
    line-height: 18px;
    border-bottom: 1px solid #ddd;
}

.regions li.regcenter {
    margin-left: 0 !important;
}

.regions li {
    margin-left: 20px;
}

.regions li a {
    border-color: white;

    display: block;

}

div.regions {
    float: left;
}

div.regions > div {
    width: 295px;
    line-height: 20px;
    font-size: 1.2em;
}

.regions_block {
    padding: 15px 50px;
}

/* doctor */

.doctor_experience h3{
    margin-bottom: 5px;
}

.doctor_main_info_td h3, .doctor_main_info_td h2{
    margin-top: 10px;
    margin-bottom: 5px;
}

.doctor_main_info_td {
    min-width: 500px;
    vertical-align: top;
}

.add_doctor_rate {
    margin: 20px 0 10px 0;
}

.doctor_manipulations, .doctor_services {
    padding-left: 0;
}

.doctor_manipulations li, .doctor_services li {
    list-style: none;
}

.doctor_manipulations li a {
    text-decoration: none;
    border-bottom: 1px gray dashed;
}

.doctor_manipulations {
    margin-top: 0;
}

.doctor_services {
    width: 100%;
}

.doctor_page_avatar {
    width: 150px;
    height: 200px;
}

.doctor_page_price {
    margin-top: -10px;
    font-size: 1.1em;
}

.doctor_page_social, .lpu_page_social {
    display: inline-block;
    height: 38px;
    text-align: center;
    line-height: 12px;
    position: relative;
    top: 3px;
    color: gray;
    vertical-align: middle;
    width: 94%;
}

.doctor_page_price > span:first-child {
    /*margin-right: 10px;*/
}

.add_doctor:hover {
    color: #0071ff;
}

#prices td {
    vertical-align: top;
}

.spec_prices {
    margin-left: 30px;
}

.doctor_address_lpu {
    display: inline-block;
    width: 680px;
    vertical-align: top;
    padding-left: 5px;
}

.shw span {
    color: #0091ff;
    font-weight: 500;
    border-bottom: 1px dotted blue;
    line-height: 1.5em;
}

.shw span:hover {
    cursor: pointer;
    border-bottom: 1px solid blue;

}

.doctor_head_spec, .pharm_head_tags {
    padding: 0;
    font-size: 1.5em;
    position: relative;
    bottom: 10px;
}

.pharm_head_tags {
    bottom: 0;
}

.doctor_head_spec a, .pharm_head_tags a {
    border: none;
    text-decoration: none;
}

.doctor_head_spec a:hover, .pharm_head_tags a:hover {
    border: none;
    color: red;
}

.pharm_head_tags a {
    border-bottom: 1px dotted black;
}

.pharm_head_tags a:hover {
    border-bottom: 1px dotted red;
}

.stepen div {
    margin-bottom: 5px;
}

.stepen {
    background: #EEE none repeat scroll 0% 0%;
    border-radius: 10px;
    width: 235px;
    padding: 8px 15px;
    margin-right: 15px;
    border: 1px solid #DDD;
    height: 60px;
    box-sizing: border-box;
}

.rnd-full {
    background: #0B0;
    width: 10px;
    height: 10px;
    border-radius: 10px;

}

.rnd-empty {
    background: #fff;
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

.rnd-list {
    height: 9px;
    width: 250px;
}

.rnd-list div {
    float: left;
    margin: 2px 2px 0;
    height: 10px;

}

.rnd-list div.label {
    margin: 0px 15px;
    line-height: 12px;
}

.linklist {
    width: 1000px;
}

.linklist a {
    margin-right: 10px;
}

.doctor_description_text {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    margin-top: 1em;
}

.super tr.header td, .super2 tr.header td, .campaigns_archive_table tr.header td {
    font-weight: 500;
}

.super, .campaigns_archive_table {
    border-collapse: separate;
    border-spacing: 0;
    text-shadow: 0 1px 0 #fff;
    color: #2a2a2a;
}

.campaigns_archive_table {
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.campaigns_archive_table td {
    border: 1px solid #ccc;
    width: 110px;
}

.super_line{
    border-bottom: 1px solid #ddd;
}

.super td, .super2 td, .campaigns_archive_table td {
    height: 24px;
    line-height: 18px;
    padding: 0 5px;
    box-shadow: 0 1px 0 white;
    -moz-box-shadow: 0 1px 0 white;
    -webkit-box-shadow: 0 1px 0 white;
    display: table-cell;
}

.super .thead td {

    font-weight: 500;
    -moz-border-radius-topright: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top: 1px solid #eaeaea;

}

.super th {
    text-align: left;
    padding: 0px 5px;
}

.super .tfoot td {
    border-bottom: 1px solid #dadada;
}

.no_border, .no_border td {
    border: none;
}

/* Lpu */

.i {
    color: #555;
    font-size: 0.8em;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-top: -10px;
    margin-right: 120px;

    padding-left: 40px;
    background: url("/static/_v1/pd/icons/statuses/circle-info-mono.png") no-repeat left transparent;

}

.i .map {
    color: black;
    border-color: black;
}

span.metro_dist {
    color: #aaa;
    font-size: 0.9em;
}

.metro-block {
    margin-bottom: 10px;
}

.metro-block a {
    white-space: nowrap;
    text-decoration: none;
    border-bottom: 1px dotted #aaa;
}

.metro-block a:hover {
    border-bottom: 1px dotted red;
}

.metro-block b:first-child{
    /*padding-left: 0px !important;*/
}

.doctorlist_metro_block > span {
    display: inline-block;
}

.no_appointment.red {
    font-weight: normal;
}

a.map {
    border-bottom: 1px dotted #00AA00;
    color: #00AA00;

}

a.map:hover {
    border-bottom: 1px solid #00AA00;
}

.deleted {
    background: url("/static/img/icon/deleted.png") no-repeat transparent;
    margin-left: 100px;
    padding-left: 20px;
}

span.checked {
    background: url("/static/_v1/pd/icons/tick/tick-in-circle.png") no-repeat scroll left center transparent;
    padding: 2px 20px;
    display: inline-block;
}

#site a, #site button {
    /*color:#0091ff;*/
    /*border-color:#0091ff;*/
    line-height: 20px;
    text-decoration: none;
    /*font-weight: 500;*/
    border: none;
    padding: 0;
    font-size: 14px;
    background: none;
}

#site a:hover, #site button:hover {
    color: #EE0000;
}

#pay {
    background: url("/static/_v1/pd/icons/pay.png") no-repeat scroll left center transparent;
    padding-left: 20px;
    line-height: 25px;

}

/* metro */

.metro {
    position: relative;
    top: .45em;
    float: left;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -khtml-border-radius: 4px;
}

/* eggs  */
.rd {
    background: red;
}

.grn {
    background: #00BB00;
}

.outoval {
    background: #AAAAAA;
    border-radius: 14px;
    border-width: 0px;
    float: left;
    height: 60px;
    margin-right: 5px;
    position: relative;
    width: 40px;
    z-index: 1;
}

.outoval_l {
    background: #AAAAAA;
    border-radius: 40px;
    float: left;
    height: 120px;
    margin-right: 5px;
    position: relative;
    top: -30px;
    width: 80px;
    z-index: 1;
}

.outoval_big {
    background: #AAAAAA;
    border-radius: 20px;
    border-width: 0px;
    float: left;
    height: 60px;
    margin-right: 5px;
    position: relative;
    width: 80px;
    z-index: 1;
}

.inoval_big {
    background: white;
    border-radius: 14px;
    height: 48px;
    left: 0;
    margin: 6px;
    position: absolute;
    top: 0;
    width: 68px;
    z-index: 4;
}

.inoval_big div {
    margin-top: 3px;

}

.inoval_big span {
    font-size: 1.8em;
    font-weight: 500;
    display: block;
    margin-top: -3px;
}

.oval_big {
    border-radius: 20px;
    border-width: 0px;
    height: 60px;
    position: absolute;
    width: 80px;
    z-index: 2;
}

.cutoval {
    overflow: hidden;
    position: relative;
    z-index: 4;
    float: bottom;
}

.oval {
    border-radius: 14px;
    border-width: 0px;
    height: 60px;
    position: absolute;
    width: 40px;
    z-index: 2;
}

.inoval {
    background: white;
    border-radius: 11px;
    height: 54px;
    left: 0;
    margin: 3px;
    position: absolute;
    top: 0;
    width: 34px;
    z-index: 4;
}

div.rating {
    clear: both;
    height: 60px;
    width: 100%;
    margin-bottom: 40px;
}

.val {
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: 30px;
    width: 100%;
    z-index: 6;
}

.oval_l {
    border-radius: 40px;
    height: 120px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
    z-index: 4;
}

.val_l {
    font-size: 1.2em;
    font-weight: 500;
    left: 6px;
    position: absolute;
    top: 30px;
    z-index: 6;
}

.no_app {
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    margin: 0 3px 0 20px;
}

#allrate, .send_rate button {
    width: 166px !important;
    background-color: #0091ff;
}

#allrate:hover, .send_rate button:hover {
    background: #0081e2 !important;
}

#allrate, .new_send_rate button {
    width: 166px !important;
    background-color: #0091ff;
}

#allrate:hover, .new_send_rate button:hover {
    background: #0081e2 !important;
}

a.findzapis {
    /*color: #0091ff;*/
    /*font-size: 1.2em;*/
    color: red;
    margin-left: 20px;
}

.drugrate, .reg-submit:enabled, .ratereply {
    text-decoration: none;
    width: 120px;
}

#drugrate, .reg-submit, .ratereply {
    border: 1px solid #0091ff;
    border-radius: 20px;
    /*color: white;*/
    display: block;
    font-size: 1.2em;
    font-weight: 500;
    margin-right: 15px;
    margin-top: 12px;
    padding: 0.3em 1em !important;
    text-align: center;
    height: 30px;
}

.ratereply {
    background-color: #0091FF;
    /*border: 1px solid #0051ff;*/
    color: white;
}

.reg-submit {
    background: #0091ff;
    color: white;
    border-color: #0091ff;
}
.reg-submit:disabled {
    background-color: #CCCCCC;
    background: #eeeeee !important;
    color: grey !important;
    border-color: #aaaaaa !important;
}

#allrate:hover, .ratereply:hover {
    background-color: #0091ff;
    border-color: #0091ff !important;
    color: white !important;
    cursor: pointer;
}

.ratereply:hover {
    background-color: #0051FF;
}

.ratereply_pg {
    width: 150px;
    height: 25px;
    font-size: 1em;
    padding: 2px !important;
    float:right;
}

.btn2 {
    background-color: #999;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#bbb));
    background-image: -webkit-linear-gradient(top, #eee, #bbb);
    background-image: -moz-linear-gradient(top, #eee, #bbb);
    background-image: -ms-linear-gradient(top, #eee, #bbb);
    background-image: -o-linear-gradient(top, #eee, #bbb);
    background-image: linear-gradient(to bottom, #eee, #bbb);
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #eee), color-stop(100%, #bbb));
    border-radius: 15px;
    border: 1px solid #eee;
    padding: 0.2em 0.5em !important;
    display: block;
    margin-top: 5px;
    text-align: center;
    width: 180px;
}

.btn {
    background-color: #999;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#bbb));
    background-image: -webkit-linear-gradient(top, #eee, #bbb);
    background-image: -moz-linear-gradient(top, #eee, #bbb);
    background-image: -ms-linear-gradient(top, #eee, #bbb);
    background-image: -o-linear-gradient(top, #eee, #bbb);
    background-image: linear-gradient(to bottom, #eee, #bbb);
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #eee), color-stop(100%, #bbb));
    border-radius: 15px;
    border: 1px solid #eee;
    margin: 5px 5px 20px 300px;
    padding: 0.3em 1.2em !important;
}

.btn:hover, .btn2:hover {
    background-color: #ccc;
    background-image: none;
    border-color: #eee !important;
    color: black !important;
    cursor: pointer;
}

#photo table {
    width: 100%;
}

#photo img {
    margin: 0 auto;
}

/* ??????? ???????*/

.rate_gallery {
    margin-left: 40px;
}

.rate_img {
    margin: 5px;
    display: inline-block;
}

.rates {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
}

.rates td {
    padding: 0px 0 0 15px;
    padding-top: 3px !important;
    vertical-align: top;
}

.rates > tbody > tr > td:first-child {
    max-width: 80px;
    width: 80px;
    padding-top: 20px;
    box-sizing: border-box;
}

.rates p {
    margin-right: 10px;
}

.rates img.avatar {
    width: 75px;
}

.rates .author {
    margin-top: 10px;
}

.rates .author > img {
    max-width: 100%;
}

.avatar_50 {
    max-width: 50px;
}

.datetime {
    position: relative;
    padding-left: 25px;
    float: right;
    color: gray;
}

.moder .datetime {
    float: none;
}

.comment br, .comment_plus br, .comment_minus br, .comment2 br, .pd_comment2 br {
    margin-bottom: 10px;
}

.comment, .comment_plus, .comment_minus, .comment2, .pd_comment2 {
    padding-left: 45px;
    font-size: 14px;
    line-height: 18px;
}

.comment_plus {
    background: url("/static/_v1/pd/icons/review/comment-plus.png") no-repeat scroll 25px top transparent;
}

.comment_minus {
    background: url("/static/_v1/pd/icons/review/comment-minus.png") no-repeat scroll 25px top transparent;
}

.comment {
    background: url("/static/_v1/pd/icons/review/comment.png") no-repeat scroll 25px top transparent;
}

/* ??????????? ?? ????? */
.use {
    margin-bottom: 10px;
    padding: 2px;
    text-align: right;
    margin-right: 10px;
}

.redlink, .redl {
    color: #BB0000 !important;
    margin-left: 5px;
    padding: 2px;
}

.greenlink, .greenl {
    color: #008800 !important;
    padding: 2px;
}

.redlink img, .greenlink img, .greenl img, .redl img {
    margin-right: 3px;
}

h2 {
    margin: 5px 0;

}

/* footer */
#footer {
    /*border-top: 1px solid #CCCCCC;*/
    height: 234px;
    margin: 10px auto 0;
    padding-top: 10px;
    position: relative;
    width: 995px;
}

.footer_block {
    background-color: #f5f5f5;
}


#footer table {
    width: 90%;
}

#footer table td {
    vertical-align: top;
}

#footer .h4 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 0px;
}

#footer li {
    font-size: 14px;
    list-style: none;
    margin-bottom: 5px;
    text-align: left;
}

#footer_menus {
    font-size: 0;
}

#footer ul {
    margin: 0 0 0 13px;
    padding: 0;
}

#footer_copyright {
    font-size: 12px;
    left: 0px;
    position: absolute;
    top: 155px;
}

#age_rate {
    font-size: 25px;
    font-weight: 500;
    left: 240px;
    position: absolute;
    top: 22px;
}

#age_rate.age_rate_pd {
    left: 655px;
}

#footer_text {
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 148px;
    width: 700px;
    font-size: 14px;
}

#footer_text.footer_text_pd {
    width: 1000px;
}

.pd_license {
    float: right;
    width: 57px;
    line-height: 1.5em;
}

/* admin links */
.adminka {
    background: yellow;
    margin: 0px 0.3em;
}

.adminka_as_link {
    border: none;
    height: initial;
    padding: initial;
    font-size: 14px;
    text-decoration: underline;
}

/* ??????? */
.headstats {

    background-color: #F3F6FA;
    border-bottom: 1px dashed #ccc;
    border-top: 1px dashed #ccc;
    color: grey;
    height: 15px;
    padding: 2px 0 0 0;
    text-align: center;
    width: 1000px;
}

::-webkit-input-placeholder {
    font-size: 12px;
    /*padding-left: 4px;*/
}

::-moz-placeholder {
    font-size: 12px;
    /*padding-left: 4px;*/
}

#usertown {
    text-align: center;
}

.img_link {
    background: transparent url('/static/_v1/pd/icons/arrows/link-arrow-right-gray.png') center top no-repeat;
    border: none;
    display: inline-block;
    height: 24px;
    margin-left: 10px;
    text-decoration: none;
    width: 24px;
}

.img_link:hover {
    background-image: url('/static/_v1/pd/icons/arrows/link-arrow-right-red.png');
    border-bottom: none;
}

.search {
    display: inline;
    float: left;
    height: 100%;
    margin: 5px 0px 0px 0px;
    width: 318px;
}

.searchclinic {
    background-color: #a7eeff;
}

.searchdoc {
    background-color: #aaff9a;
}

.searchdrug {
    background-color: #fbff5d;
}

.search > a {
    bottom: 4px;
    font-size: 1.7em;
    font-weight: 400;
    margin: 3px 0 0px 35px;
    position: relative;
}

.search a {
    text-decoration: none;
}

.innersearch {
    border: 1px solid #ddd;
    border-radius: 12px;
    /* margin: 0px 0px 20px 20px; */
    height: 60px;
    margin-bottom: 10px;
    width: 100%;
}

.innersearch > * {
    margin: 3px 0 0 35px;
}

.innersearch > span {
    font-size: 10px;
    position: relative;
    top: 5px;
}

.stats {
    clear: both;
}

.stats table {
    /* width: 700px; */
    font-size: 1.7em;
    margin: 0 auto;
    padding: 10px 0;
}

.stats td {
    padding: 0 20px;
    text-align: left;
}

.stats td:nth-child(odd) {
    padding-right: 110px;
}

.img_stats {
    vertical-align: middle;
    /* height: 50px; */
    width: 50px;

}

/* new doctors and clinics */

.new {
    overflow: hidden;
    padding-bottom: 20px;
    width: 100%;
}

.new > div > div {
    padding-top: 7px;
}

#newdocs, #newclinics {
    padding-right: 20px;
}

.new a {
    font-weight: 500;
}

.last_otzyv td {
    vertical-align: top;
}

/* ??? ?????? */

.review-link {
    font-weight: 500;
    height: 1.4em;
    vertical-align: middle;
}

/* ????? */
table.trating {
    margin-left: 20px;
    width: 97%;
}

table.trating td {
    padding: 2px;
    vertical-align: top;
}

table.trating table {
    margin: 5px;
}

.h2rating {
    color: #0091ff !important;
    padding: 5px 0 0 5px;
    font-size: 1.3em;
    font-weight: 500;
    border-color: #0091ff;

}

.h2rating:hover {
    border-color: #0091ff;
}

.h2otzayv {
    color: #EE0000 !important;
    padding: 15px 0 0 15px;
}

/* lpu list */

.h1_800 {
    max-width: 800px;
    float: left;
}

h2#rating {
    margin-top: 0;
}

table.lists {

    width: 100%;

}

table.lists li {
    line-height: 20px;
}

.lists ins {
    margin-top: -18px;
}

.lists td {
    vertical-align: top;
}

.lists td:nth-child(2) {
    width: 336px;
}

.lists td:nth-child(1) {
    padding-right: 10px;
}

.intro {
    /*min-height: 40px;*/
    max-width: 650px;
}

.intro_full {
    max-width: 100%;
}


.h1_cnt {
    display: inline-block;

    font-weight: 500;
    font-size: 1.5em;
    margin-left: 15px;
}

.nav-up {
    background: url("/static/_v1/pd/icons/arrows/circle-mono-up-sm.png") no-repeat right transparent;
    width: 50px;
    height: 50px;
    z-index: 1;
    display: block;
    float: right;
    border: 0;
    margin-top: -15px;
    opacity: 0.5;

}

.nav-up:hover {
    opacity: 1;
    border: 0;
}

.lpu-list {
    border-collapse: collapse;
    width: 100%;
}

.lpu-list tr + tr:hover {
    background-color: #eee !important;
    cursor: pointer;
}

.lpu-list tr + tr:hover a {
    color: red;
}

.th-rating {
    width: 50px;
}

.lpu-list td:nth-child(3), .lpu-list th:nth-child(3) {
    text-align: left !important;
    padding-left: 10px;
}

.lpu-list td:nth-child(4) {
    font-size: 1.4em;
}

.lpu-list td a {
    font-weight: 500;
    font-size: 1.4em;
    border-width: 0 !important;
}

.lpu-list a div {
    color: gray;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
}

.lpu-list td:nth-child(4), .lpu-list th:nth-child(3) {
    text-align: right;
    width: 150px;

}

.lpu-list th:first-child {
}

.lpu-list td:first-child {
    font-size: 1.5em;
}

.lpulist_name {
    color: #0071FF;
    font-weight: 500;
    position: relative;
    bottom: 3px;
    border-bottom: medium none;
    text-decoration: none;
    font-size: 1.4em;
}

a.lpulist_name:hover {
    color: #0071FF;
    text-decoration: underline;
}

.dr_cnt {
    margin: 5px 0;
    color: #858585;
    font-weight: 500;
    text-align: left;
    float: left;
}

.doctor-list {
    border-collapse: collapse;
    width: 100%;
}

.sort {
    padding: 10px 2px;
    font-weight: 500;
    font-size: 1.2em;
    height: 20px;
    width: 30%;
}

#sort-rating {
    float: right;
}

#sort-fio {
    float: left;
}

.sort a, .sort div {
    border: 0 !important;

}

.sort a {
    color: gray;
    display: inline-block;
    text-decoration: underline !important;
}

.sort a:hover {
    color: red;
}

.sort div {
    background: url("/static/_v1/pd/icons/arrows/drop-down-sorted.png") no-repeat right transparent;
    color: gray;
    padding-right: 13px;
}

.filters {
    color: gray;
    float: right;
    font-size: 1.2em;
    font-weight: 500;
}

.doctor-list td {
    vertical-align: top;
    width: 30%;
}

.doctor-list td + td {
    padding-left: 25px;
}

.doctor-list ul {
    padding: 0px;
    margin: 0;
}

.doctor-list li {
    list-style: none;
    padding: 6px 2px 0 2px;
    width: 220px;
}

.doctor-list li:hover {
    background-color: #eee;
    cursor: pointer;
}

.doctor-list li.caption:hover {
    background-color: none;
    cursor: default;
}

.doctor-list li:hover a {
    border-bottom: 1px solid red;
    color: red !important;
}

.doctor-list a {
    display: block;
    font-weight: 500;
}

.doctor-list li span {
    float: right;
}

.lpu-list th {
    color: gray;
    font-size: 1.4em;
    padding: 7px;
}

.lpu-list th a:hover {
    color: red;
    cursor: pointer;
}

.lpu-list td {
    border-top: 1px solid #eee;
    text-align: center;
    vertical-align: middle;
    height: 70px;
}

.lpu-list th a {
    border: 0;
    color: gray;
    display: block;
    text-decoration: underline !important;
}

.sorted {
    background: url("/static/_v1/pd/icons/arrows/drop-down-sorted.png") no-repeat right transparent;
    color: gray;
    display: block-inline;
    padding-right: 13px;
}

.mesto {
    color: #AAAAAA;
    font-size: 25px;
    vertical-align: middle;
}

div.circle {
    background: #CD7F32;
    border-radius: 50px/50px;
    color: white !important;
    cursor: help;
    vertical-align: middle;
    width: 50px;
}

.circle2 {
    background: #CD7F32;
    border-radius: 30px/15px;
    color: white !important;
    cursor: help;
    margin-left: 7px;
    width: 30px;
}

.circle3 {
    background: #CD7F32 !important;
    border-radius: 15px/15px;
    color: white !important;
    height: 15px;
    margin-left: 7px;
    width: 15px;
}

td.td50l, td.td50r {
    vertical-align: top;
    width: 50%;
}

.ser, .ser a, a.ser {
    color: #999999;
}

a.customLink {
    border: 0;
    cursor: pointer;
}

a.customLink img:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
    opacity: 0.7;
}

/* ????????? */

.latname {
    color: grey;
    font-size: 1.2em;
    font-weight: 500;
}

.fordocs {
    color: red;
    font-weight: 500;
    font-size: 1.2em;
}

.select2-container .select2-choice {
    height: 20px !important;
    line-height: 20px !important;
    margin-top: -2px;
}

.select2-arrow b {
    bottom: 4px !important;
    position: relative !important;
}

.ins_hidden {
    display: none;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

#left li.active a {
    color: #EE0000;
}

.moder {
    background: white;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -khtml-border-radius: 10px;

    padding: 0 0 0 10px;

    width: 790px;
    display: inline-block;

}

.moder div {
    font-family: $font-family-regular;
    margin-bottom: 3px;
    /*color: #0091ff;*/
}

.review-params td {
    vertical-align: top;
}

.status1, .status2 {
    font-weight: 500;
}

.status1 {
    color: green;
}

.status2 {
    color: red;
}

.status0 {
    color: blue;
}

.li_ru {
    float: right;
    z-index: 5;
    margin-right: 5px;

}

.li_ru a {
    border: 0;
}

.li_ru img:hover {
    cursor: pointer;
}

.expo {
    padding: 5px;
    background-color: #CCFFCC;
    border: 1px solid #88FF88;

}

/* ???????? ????????? */
.ask {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 3px;
}

.interview {
    margin: 10px;
}

/*-----STATIC_PAGES_FROM_FOOTER-----*/

.static_page_wrap p {
    line-height: 18px;
    margin-bottom: 15px;
}

.static_page_wrap p.ques b {
    display: block;
    font-size: 14px;
    margin-bottom: 2px;
}

.static_page_wrap table {
    margin: 10px 0;
    line-height: 18px;
    border-collapse: collapse;
}

.static_page_wrap table tr > *:first-child {
    width: 150px;
}

.static_page_wrap table.auth_table tr > *:first-child {
    width: 200px;
    text-align: left;
}

.static_page_wrap table td {
    border-bottom: 1px solid #ccc;
    line-height: 18px;
    padding: 10px 15px;
}

.static_page_wrap table.auth_table td {
    text-align: center;
}

.static_page_wrap table td {
    margin: 0 !important;

}

.ui-widget-header {
    background: #eee !important;
    border-color: #ccc !important;
    padding-left: 20px !important;
}

a.pdback {
    display: block;
    border: 1px solid #aaaaaa !important;
    border-radius: 10px;
    padding: 5px;
    background: #eee;
}

a.pdback:hover {
    background: #fff;
}

.tdimg {
    width: 55px;
}

/*.tdimg img {*/
    /*max-width: 50px;*/
    /*max-height: 50px;*/
/*}*/

.msk-spb {
    font-size: 1.2em;
    font-weight: 500;

}

.yashare-auto-init {
    margin: 0 0 10px;

}

.yashare-auto-init a {
    border: 0;
}

.yashare-auto-init span.b-share {
    padding: 2px 0 !important;
}

.share_block {
    float: right;
    text-align: right;
}

.share {
    font-size: 10px;
    margin-top: 10px;

}

.share2 {
    font-size: 10px;
    margin: 10px 0 0 60px;
}

.ui-button-text-only .ui-button-text {
    padding: .1em 1em .2em !important;
    font-size: 1em !important;
}

/*new button delete edit*/
.del, .edit, .bbb {
    font-family: $font-family-regular;
    border: 1px solid #ccc !important;
    padding: 2px 10px;
    margin: 5px;
    border-radius: 4px;
    background-color: #eee;
    color: #555;
    font-size: 12px;
    font-weight: 500;

}

.del, .edit {
    width: 70px;
    height: 15px;
}

.bbb {
    font-size: 1.2em;
    text-decoration: none;
}

input[type="submit"].del, input[type="submit"].edit {
    height: 21px !important;
    width: 70px !important;
    position: relative;
    bottom: 1px;
}

.del:hover, .edit:hover, .bbb:hover {
    background-color: #DDD;
    cursor: pointer;
    color: black;
}

.errors {
    color: red;
}

input[type='text'].error, input[type='email'].error, .input_error {
    border: 1px solid red !important;
}

.lpu-info td:first-child {
    vertical-align: top;
}

#doctor-card {
    width: 360px;

}

#doctor-card td {
    vertical-align: top;

}

.specialnosti {
    padding-bottom: 10px;
}

.doctordrugs th, .doctordrugs a {
    font-weight: 500;
}

.doctordrugs td, .doctordrugs th {
    border-bottom: 1px solid #ccc;
    line-height: 20px;
    text-align: center;
    padding-right: 20px;
}

.doctordrugs td:nth-child(2) {
    text-align: left;
}


.move-top {
    padding-left: 16px;
    background: url("/static/_v1/pd/icons/arrows/link-arrow-up-red.png") no-repeat scroll left center transparent;
}

#mistakebtn {
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
}

.mistake_right {
    float: right;
    font-size: 14px;
}

.lastmod_dt_left {
    margin-left: 190px;
    float: left;
    color: #9c9c9c;
}

.lastmod_lpu_dt_left {
    margin-left: 219px;
    float: left;
    color: #9c9c9c;
}

.lastmod_block {
    margin-top: 10px;
    margin-bottom: 10px;
}

li.regcenter a {
    color: #e00;
    font-weight: 500;
    font-size: 1.1em;
}

.ser-left {
    color: gray;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    margin-top: 10px;
}

ul.lastrates {
    padding-left: 0px;
    margin: 10px 10px 10px 0;
    list-style: none;
}

.lastrates li {
    padding-bottom: 15px;
    padding-right: 20px;
    box-sizing: border-box;
    max-width: 50%;
    float: left;
}

.lastrates li:nth-child(odd) {
    clear: both;
}

.lastrates blockquote {
    color: gray;
    margin-top: 5px;
    display: inline;
}

.lastrates a {
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
}

.lastrates div:not(:last-child) {
    float: left;
}

.lastrates .rate_text {
    font-style: italic;
}

.lastrates .rate_quote {
    width: 360px;
    float: left;
}

.lastrates blockquote {
    display: inline-block;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.lastrates .avatar_container {
    width: 75px;
    height: 75px;
    line-height: 75px;
    border-radius: 38px;
    display: inline-block;
    float: left;
    text-align: center;
    margin: 0 25px 0 0;
    overflow: hidden;
}

.lastrates .avatar_container img {
    max-width: 75px;
    cursor: pointer;
}

.lastrates .rate_date {
    text-align: right;
    float: right !important;
    color: gray;
}

blockquote:before {
    content: "\00ab ";
}

blockquote:after {
    content: " \00bb";
}

.blockquote_link:after {
    content: '';
}

.blockquote_span:after {
    content: " \00bb";
}

.region-text p {
    width: 600px;
}

.region-text h2 {
    clear: both;
}

.sidebar_menu ul {
    line-height: 20px;
    margin: 0;
    padding: 0 20px;
    list-style-image: url("/static/_v1/pd/icons/arrows/bolder-arrow-right.png");
}

#soc-btn {
    padding-top: 5px;
}

#soc-btn a {
    margin: 7px;
}

/* feedback  */
form#feedback {
    display: none;
}

.row {
    margin-bottom: 10px;
}

.row .label {
    display: inline-block;
    width: 120px;
}

.row input[type=text] {
    padding: 2px 5px;
    width: 564px;
    display: inline-block;
    vertical-align: baseline;
}

.row textarea {
    display: inline-block;
    width: 570px !important;
    vertical-align: top;
}

form input[type="submit"] {
    display: block;
    margin-top: 5px;
    cursor: pointer;
}

.all_doctors {
    background-color: #999;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#bbb));
    background-image: -webkit-linear-gradient(top, #eee, #bbb);
    background-image: -moz-linear-gradient(top, #eee, #bbb);
    background-image: -ms-linear-gradient(top, #eee, #bbb);
    background-image: -o-linear-gradient(top, #eee, #bbb);
    background-image: linear-gradient(to bottom, #eee, #bbb);
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #eee), color-stop(100%, #bbb));

    border-radius: 15px;
    border: 1px solid #ddd;

    display: none;
    float: right;
    font-weight: 500;

    padding: 0.3em 1.2em !important;
}

.all_doctors:hover {
    background-color: #ddd;
    border-color: #ddd;
    background-image: none;
    color: black;

}

/* lpu home */
.lpu_status_header {
    margin-left: 5px;
    font-weight: 500;
}

.lpu_status_body {
    margin-left: 15px;
    margin-bottom: 5px;
}

/* town lputypes map */

.loading {
    color: #9b9b9b;
}

.anonym {
    background: url(/media/img/anonym.jpg) no-repeat;
    height: 70px;
    width: 48px;
}

.datetime[data] {
    cursor: pointer;
}

/* Rates */
.ratetype_name {
    margin-top: 10px;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 500;
}

.rate_time {
    font-size: 0.8em;
    text-decoration: none !important;
}

.ratetype_img {
    height: 40px;
    float: left;
    padding: 5px;
}

.ifr_newrate {
    width: 100%;
    border: none;
}

#very_simple_rate {
    padding: 10px;
}

/*.send_rate button {*/
/*width: 150px;*/
/*height: 30px;*/
/*font-size: 1.2em;*/
/*font-weight: 500;    */
/*background: #808C9A;*/
/*color: white;*/
/*border-radius: 20px;    */
/*border-width: 0px;*/
/*}*/

/*.send_rate button:hover {*/
/*cursor: pointer;*/
/*background: #0071ee;*/
/*}*/

.add_photo {
    font-size: 0.9em;
}

.error_text {
    visibility: hidden;
    background-color: red;
    display: inline-block;
    border: 1px solid #da0000;
    color: #ffffff;
    padding: 4px;
    /*width: 280px;*/
    font-weight: 500;
}

.error_mark, .gender_error, .age_error, .pay_error, .diagnos_error {
    background-color: red;
    border: 1px solid #da0000;
    color: #ffffff;
    padding: 2px;
    /*width: 102px;*/
    font-weight: 500;
    font-size: 0.9em;
}
.error_mark {
    display: none;
}

.gender_error, .age_error, .pay_error, .diagnos_error {
    display: inline-block;
    visibility: hidden;
    margin-left: 10px;
}

.photo_container {
    float: left;
}

.delete_image {
    border-bottom: none;
}

.rate_field_title {
    font-size: 0.9em;
    padding-left: 15px;
}

.rate_param_title {
    display: inline-block;
    width: 50px;
    line-height: 2.2em;
    font-size: 0.9em;
    padding-left: 20px;
}

#diagnostics_tab_container .tab.active, .uslugi_tab_container .tab.active {
    background: whitesmoke;
}


#id_text, #id_comment, #id_comment_plus, #id_comment_minus {
    width: 98%;
}

div.txt {
    width: 105px !important;
}

#id_diagnosis {
    width: 120px;
}

#id_diagnos {
    max-width: 200px;
}

.draft_detail {
    display: table;
    width: 100%;
    box-sizing: border-box;
}

.draft_detail > div:first-child {
    width: 120px;
}

.draft_detail > div {
    display: table-cell;
    vertical-align: top;
}

.draft_detail img {
    max-width: 100px;
}

.draft_detail_text > div {
    margin-bottom: 5px;
}

.draft_detail_name a {
    color: #0071FF;
    font-size: 1.5em;
    font-weight: 500;
}

.draft_detail_name a:hover {
    color: red;
}

.finish_draft {
    font-size: 1.1em !important;
    display: inline-block;
    background-color: #0A0;
    color: white !important;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 500;
}

.finish_draft:hover {
    background-color: #090;
}

.draft_status_wrap:after {
    content: '';
    display: table;
    clear: both;
}

.draft_status_wrap {
    float: right;
}

.draft_status_container {
    position: relative;
    display: inline-block;
    height: 0px;
    width: 100%;
}

@media screen and (max-width: 720px) {
    .draft_status_container {
        height: 100%;
    }
}

.draft_status {
    background-color: #e9e9e9;
    background-image: url("/static/_v1/pd/icons/save-icon.png");
    background-repeat: no-repeat;
    background-position: 1% 50%;
    border: 1px solid gray;
    border-radius: 10px;
    display: inline-block;
    width: 140px;
    height: 30px;
    text-align: center;
    /*line-height: 30px;*/
    padding-left: 20px;
    vertical-align: middle;
}

.delete_draft {
    border-bottom: none;
}

.delete_draft img {
    vertical-align: middle;
}

.tooltip_button {
    width: 50px;
    margin-top: 10px;
}

.rate_status {
    color: white;
    font-weight: 500;
    padding: 3px;
    position: absolute;
    left: 40%;
    /*margin: 0 auto;*/
    text-align: center;
    top: 0;
}

div.rate_status_desc {
    top: 0 !important;
    left: 0;
}

.rate_status:not(.rate_status_widget) {
    top: 40px;
}

.doctor_rate_status {
    left: 60%;
}

.rate_status_moderation {
    background-color: blue;
    width: 130px;
}

.rate_status_rejected {
    background-color: red;
    width: 100px;
}

.send_rate {
    position: absolute;
    bottom: 5px;
    right: 5px;
    text-align: right;
    z-index: 1;
}

.send_rate button {
    width: 150px;
    height: 45px;
    font-size: 1.2em;
    font-weight: 500;
}

.new_send_rate button {
    height: 39px;
    line-height: 27px;
    border-radius: 5px !important;
    font-weight: 500;
    font-size: 14px !important;
    text-align: center;
    display: inline-block;
    padding: 5px !important;
    color: white !important;
    text-decoration: none;
    border: 0;
    margin-bottom: 5px;
    background-color: #0091ff;
}

.send_rate button:hover, .new_send_rate button {
    cursor: pointer;
}

.add_photo {
    font-size: 0.9em;
}

.error_text {
    visibility: hidden;
    background-color: red;
    display: inline-block;
    border: 1px solid #da0000;
    color: #ffffff;
    padding: 4px;
    /*width: 280px;*/
    font-weight: 500;
}

.error_mark, .gender_error, .age_error, .pay_error, .diagnos_error {
    background-color: red;
    border: 1px solid #da0000;
    color: #ffffff;
    padding: 2px;
    /*width: 102px;*/
    font-weight: 500;
    font-size: 0.9em;
}

.error_mark {
    display: none;
}

.gender_error, .age_error, .pay_error, .diagnos_error {
    display: inline-block;
    visibility: hidden;
    margin-left: 10px;
}

.photo_container {
    float: left;
}

.image_list {
    max-width: 420px;
    height: 50px;
    margin-top: 5px;
}

.delete_image {
    border-bottom: none;
}

.rate_field_title {
    font-size: 0.9em;
    padding-left: 15px;
}

.rate_param_title {
    display: inline-block;
    width: 50px;
    line-height: 2.2em;
    font-size: 0.9em;
    padding-left: 20px;
}

.adopt_all_rates {
    border: none;
    color: white;
    background-color: #0091FF;
    border-radius: 10px;
    padding: 5px 15px;
    height: 30px;
}

.adopt_all_rates:hover {
    background-color: #0071FF !important;
}

.towns_on_main {
    overflow: hidden;
}

.towns_on_main li {
    line-height: 2em;
}

.towns_on_main ul {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    line-height: 18px;
}

.towns_on_main ul + ul {
    margin-left: 40px;
}

.towns_on_main b {
    font-size: 1.3em;
}

.blocks_main {
    overflow: hidden;
}

.blocks_main {
    height: 570px;
    width: 1000px;
    margin: 10px 0;

}

.blocks_main > div {
    display: block;
    float: left;
    width: 304px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #eee;
    height: 100%;
    margin: 10px 0 10px 10px;
}

.blocks_main > div:first-child {
    margin-left: 0px;
}

.blocks_main > div > div {
    margin-top: 15px;
}

/* paginator */
.pager {
    padding: 20px 0 20px 35px;
    width: 960px;
}

.pager a {

    border: 1px solid #0091ff;
    color: #0091ff;
}

.pager a, .pager .current {
    margin: 3px;
    text-decoration: none;
    line-height: 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;

    border-radius: 5px;
    font-weight: 500;
    font-size: 1.1em;
    background-color: white;
}

.pager .current {
    color: #444;
    border: 1px solid #ddd;

}

.ellipsis {
    font-weight: 500;
    font-size: 1.5em;
    color: #0091ff;
    margin: 3px;
}

.doctor_addr {
    margin-top: 10px
}

.positive, .negative, .normal {
    display: inline-block;
    height: 20px;
    width: 100px;
    border-radius: 5px;

}

.positive span, .negative span, .normal span {
    font-size: 1.3em;
}

.positive {
    background: rgb(204, 255, 204);

}

.negative {
    background-color: red;
}

.normal {
    background-color: yellow;
}

.for_map_left {
    float: left;
    width: 400px;
    height: 400px;
}

.rates .block {
    position: relative;
}

.avg_rate {
    background-color: #0091ff;
    height: 25px;
    width: 40px;
    border-radius: 5px;
    display: inline-block;
    color: white;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 5px;
}

.avg_text, .pd_avg_text {
    color: #0091ff;
    font-size: 1.2em;
    font-weight: 500;
}

.tabs {
    text-align: right;
    padding-right: 15px;
}

.tabs li {
    margin-right: 3px;
}

.newrate_header {
    float: left;
    font-weight: 500;
    font-size: 1.5em;
    position: relative;
    top: -30px;
}

.lpu_address {
    position: relative;
}

.lpu_address .address {
    display: inline-block;
    max-width: 65%;
    font-size: 1.3em;
}

.registerbtn3 {
    color: gray;
    border: none;
    border-radius: 0;
    outline: none;
    border-bottom: 1px dotted gray;
    text-decoration: none;
    line-height: 15px;
    height: auto;
    background-color: transparent;
    margin: 0;
    padding: 0;
}

.registerbtn3:hover {
    background-color: transparent;
    color: #c00 !important;
    border-bottom: 1px dotted red;
}

.mainpage {
    width: 100%;
}

.mainpage td {
    height: 100px;
}

.mainpage > div > div {
    /*width: 85%;*/
    height: 80%;
}

.mainpage > div > div > div {
    margin-left: 90px;
    position: relative;
    top: 20px;
}

.mainpage ul {
    margin: 10px 0 0 10px;
    padding: 0;
}

.mainpage > div {
    float: left;
    width: 333px;
}

.mainpage span:not(.select2-chosen) {
    line-height: 20px;
    font-size: 25px;
}

.mainpage .block {
    border-radius: 15px;
    height: 100px;
}

.mainpage img {
    margin: 10px;
}

.mainpage a {
    color: red;
    border-color: red;
}

.mainpage .blue_link a, .mainpage .blue_link h2 {
    color: #0091ff;
    border-color: #0091ff;
}

.mainpage_doctors_left {
    float: left;
    width: 440px;
    line-height: 20px;
}

.mainpage_doctors_right {
    float: right;
    width: 440px;
    font-size: 1.2em;
    line-height: 20px;
}

.mainpage_description_wrap {
    height: 30px;
}

.mainpage_description {
    position: absolute;
    bottom: -10px
}

.mainpage_doctors_right ul {
    float: left;
    padding-left: 0;
    margin-top: 10px;
}

.mainpage_doctors_right ul:not(:first-of-type) {
    margin-left: 20px;
}

.mainpage_doctors_right li {
    list-style: none;
}
.mainpage_doctors_right a {
    text-decoration: none;
    border-bottom: 1px dotted #aaa;
}
.mainpage_doctors_right .all_regions a {
    text-decoration: underline;
    border-bottom: none;
}
.mainpage_pharm_groups_container {
    display: table;
    width: 100%;
}

.mainpage_pharm_groups {
    display: table-row;
}

.mainpage_pharm_groups > div {
    display: table-cell;
}

.mainpage_doctors_left > div, .mainpage_pharm_groups > div {
    margin-top: 10px;
    float: left;
    margin-right: 50px;
}

.mainpage_pharm_groups > div {
    margin-right: auto;
    float: none;
}

.mainpage_doctors_left > div a, .mainpage_pharm_groups > div a {
    display: inline-block;
}

.mainpage_doctors_left > div a:first-child, .mainpage_pharm_groups > div a:first-child {
    font-size: 1.5em;
}

.mainpage_doctors_left > div a:not(:first-child), .mainpage_pharm_groups > div a:not(:first-child) {
    font-size: 1.2em;
    margin-left: 10px;
    text-decoration: none;
    border-bottom: 1px dotted #aaa;
    line-height: 16px;
}

.mainpage_pharm_groups > div a:not(:first-child) {
    margin-top: 5px;
}

.mainpage_doctors_left > div a:not(:first-child):hover, .mainpage_pharm_groups > div a:not(:first-child):hover {
    border-bottom: 1px dotted red;
}

.all_regions {
    clear: both;
    margin-top: 10px !important;
    font-size: 1.3em;
}

.balance {

    position: relative;
    /*margin-left: 499x;*/
}

#find_place, #find_drug {
    padding-right: 20px;
}

.finddiv input[type="text"], .finddiv_main input[type="text"], #find_drug {
    /*border-color: #0091ff;*/
    width: 260px;
    border-color: #0091ff;
    border-radius: 3px !important;
    border-width: 1px !important;
    background: #FFF url('/static/_v1/pd/icons/arrows/drop-down-search.png') 99% center no-repeat;
    background-size: 16px;
}

.finddiv_main #find_drug {
    width: 925px;
}

.finddiv_help {
    color: white;
}

.finddiv_help span:first-child {
    display: inline-block;
    width: 270px;
}

.finddiv #find_place {
    width: 180px;
    height: 25px;
    margin-left: 3px;
}

#find_place ~ .tt-menu {
    width: 250px;
    margin-left: 10px;
}

.finddiv button, .pharm_search button  {
    background-color: #0091ff;
    color: white;
    font-weight: 500;
    height: 25px !important;
    border-width: 0;
    z-index: 3;
    vertical-align: top;
    margin: 0 0 0 3px;
    width: 55px;
}

.finddiv input[type=text]:focus {
    border-color: #0071ff;
}

.find_doctor_fio {
    width: 200px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.find_doctor_speclist {
    width: 135px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/*input, textarea {*/
    /*outline: none;*/
/*}*/

/*input:active, textarea:active {*/
    /*outline: none;*/
/*}*/

/*:focus {*/
    /*outline: none;*/
/*}*/


.rate1 {
    color: orangered !important;
    font-weight: 500;
    font-size: 1.1em;
    margin-right: 15px;

}

a.rate1:hover {
    color: red !important;
}

.orangered{
    color: orangered !important;
    font-size: 1.4em !important;
}

.wrapper_doctors {
    height: 200px;
}

.prof_menu li > div {
    padding-left: 20px;
    color: #999;
}

.prof_menu a {
    text-decoration: underline;
    color: #999;
}

.prof_menu li > a {
    color: #0091ff;
}

.prof_menu a:hover, .prof_menu li > a:hover {
    color: red !important;
}

.town_vrach_intro {
    margin-bottom: 30px;

}

.town_vrach_filters #input_spec {
    max-height: 20px;
}

/* Drugrate */

.doctor_drugrate {
    padding: 10px 0;
    display: table;
    border-top: 1px solid #CDCDCD;
}

.doctor_drugrate > div {
    vertical-align: top;
    display: table-cell;
}

.doctor_drugrate_avatar {
    width: 130px;
    min-height: 50px;
    text-align: center;
}

.doctor_drugrate_avatar img{
    max-width: 50px;
    max-height: 75px;
}

.doctor_drugrate_name, .doctor_drugrate_rating {
    font-size: 1.5em;
    padding-right: 10px;
    white-space: nowrap;
}

.doctor_drugrate_rheader {
    min-width: 120px;
    line-height: 16px;
    display: inline-block;
}

.doctor_drugrate_rates {
    width: 229px;
}

.doctor_drugrate_text {
    padding-top: 25px;
    width: 600px;
    position: relative;
    padding-bottom: 20px;
}

.doctor_drugrate_text blockquote {
    margin: 0;
    font-style: italic;
}

.doctor_drugrate_all_rates {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #0071FF;
}

.doctor_drugrate_all_rates a {
    color: #0071FF;
}

.doctor_drugrate_all_rates a:hover {
    color: red;
}


.ui-widget-overlay {
    background: #333 !important;
    opacity: .7 !important;
    filter: Alpha(Opacity=0);
}


#link_under_metro, #link_under_district, #link_under_district_m, #link_under_spec, #link_under_lputype{
    position: absolute;
    color: gray;
    text-decoration: none;
    border-bottom: 1px dotted gray;
}

#link_under_lputype, #link_under_spec {
    bottom: 0;
    left: 60px;
}

#link_under_spec {
    left: 116px;
}

#link_under_metro, #link_under_district, #link_under_district_m {
    bottom: 0;
    left: 390px;
}

#link_under_district {
    left: 342px;
}

#link_under_district_m {
    left: 635px;
}

.rates .block a {
    color: #0091FF;
    font-weight: 500;
}

.ita {
    font-style: italic;
}

.lpulist_all_doctors {
    margin-top: 20px;
}

.lpulist_all_doctors a {
    position: relative;
    bottom: 5px;
    float: right;
}

.lpulist_all_doctors span {
    position: relative;
    bottom: 5px;
    font-size: 1.2em;
}

.reply_datetime {
    color: gray !important;
    font-size: 0.9em;
}

.lpu_head_links {
    list-style: none;
    padding-left: 0;
}

.lpu_head_links a {
    text-decoration: none;
    border-bottom: 1px dotted black;
    line-height: 16px;
}

.lpu_head_links a:hover {
    border-bottom: 1px dotted red;
}

.lpulist_metro {
    /*padding-left: 10px;*/
    font-size: 0.9em;
    margin-bottom: 5px;
}

.doctorlist_avg_price {
    margin: 5px 0;
    display: block;
}

.lpu_head_description {
    width: 550px;
}

.lpu_head_description a {
    color: #0091FF !important;
}

span.stars {
    float: right;
    margin-right: 20px;
}

span.stars, span.stars span {
    display: block;
    width: 120px;
    height: 24px;
    background: transparent url("/static/_v1/pd/icons/old-stars/star-off.png") repeat-x scroll 0px 0px;
}

span.stars span {
    background: transparent url("/static/_v1/pd/icons/old-stars/star-on.png") repeat-x scroll 0px 0px;
}

.lpu-list span.stars, .drug-right span.stars {
    float: none !important;
}

span.stars16 {
    float: none;
}

span.stars16, span.stars16 span {
    width: 80px;
    height: 16px;
    background: transparent url("/static/_v1/pd/icons/old-stars/star-16-off.png") repeat-x scroll 0px 0px;
}

span.stars16 span {
    background: transparent url("/static/_v1/pd/icons/old-stars/star-16-on.png") repeat-x scroll 0px 0px;
}

.doctor_speclist {
    margin-top: 3px;
}

.block_docinfo {
    padding: 15px;
    width: 970px;
}

.doctor_rating_lst {
    float: right;
    padding-right: 15px;
    font-size: 1.4em;
}

.doctorlist_stars_rates {
    float: right;
    text-align: right;
}

.save_confirmed {
    background: url('/static/_v1/pd/icons/tick/auth-list.png') no-repeat right center;
}

.save_confirm_container {
    display: inline-block;
    width: 20px;
}

.to_excel, .to_excel:hover {
    float: right;
    background-color: #0091FF;
    color: white;
    border: 1px solid #0071FF;
}

.gallery_container {
    overflow: hidden;
}

.video_title {
    font-size: 17px;
    margin: 5px;
    text-align: center;
    height: 20px;
    max-width: 745px;
}

.video_title {
    max-width: 630px;
}

.tooltipster-schedule {
    background-color: #FFF;
    line-height: 20px;
    font-size: 12px !important;
    color: #000;
    padding: 10px !important;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 10px 20px -5px rgba(0, 0, 0, 0.4);
}


.tooltipster-schedule .tooltipster-arrow {
    display: none;
}

.h1_page_num {
    color: gray;
    font-size: 0.6em;
    font-weight: normal;
    padding-left: 18px;
}

.share_block_margin {
    margin-top: 12px;
}

.h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 5px 0;
}

.doctorother_see_also {
    position: relative;
    bottom: 10px;
}

.left0 {
    margin-left: 0 !important;
}

.block_main a.select2-choice {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 1.2em;
}

.block_main .select2-arrow b {
    background-position: 7px 6px !important;
}

.block_main .select2-container .select2-choice .select2-arrow {
    width: 30px !important;
}

.main_page_search button, .main_page_search input, .main_page_search #find_drug {
    height: 30px;
}

.main_page_search button {
    width: 50px;
    padding: 0 3px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.main_page_search {
    padding: 10px 5px 10px 10px;
    border-radius: 10px;
    background-color: #0091FF;
    position: relative;
}

.main_page_search select {
    width: 263px;
    height: 30px;
}

.main_page_search .search_obj {
    width: 263px !important;
}

.main_page_search .search_text {
    width: 263px;
    position: absolute;
    right: 70px;
    top: 12px;
}

.main_page_search input[type="text"] {
    width: 350px;
}

.main_page_search input.town {
    width: 200px;
}

.main_page_search input.town ~ .tt-menu {
    margin-left: 10px;
}

.main_page_search input.town, .main_page_search #find_place {
    margin-left: 10px;
}

.block_main {
    padding: 15px 50px;
}

.mainpage_h1 {
    margin-top: 50px;
    text-align: center;
}

.mainpage_h1_desc {
    text-align: center;
    line-height: 1.5em;
}

.mainpage_h2 {
    color: white;
    font-weight: normal;
    display: inline-block;
    width: 64px;
}

.mainpage_margin {
    margin-top: 60px;
}

.mainpage_margin2 {
    margin-top: 40px;
}

.main_page_search {
    margin-top: 20px;
}

.h2_link {
    display: inline-block;
}

.h2_link + .cnt {
    font-size: 16px;
    font-weight: 500;
    color: #0091FF;
    padding-left: 5px;
}

.h1_count {
    font-size: 24px;
    display: inline-block;
    margin-left: 10px;
}

/**
 * Используется:
 * @see include/doctor_intro.html
 */
.tt-menu {
    background: white none repeat scroll 0% 0%;
    width: 100%;
    max-height: 510px;
    overflow-y: auto;
    border: 1px solid #aaa;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 0 7px 13px #AAA;
}

/**
 * Используется:
 * @see include/doctor_intro.html
 */
.tt-suggestion {
    padding: 5px 10px;
    border-top: 1px solid #ccc;
    cursor: pointer;
}

/**
 * Используется:
 * @see include/doctor_intro.html
 */
.tt-cursor {
    background-color: #EEE !important;
}

/**
 * Используется:
 * @see include/doctor_intro.html
 */
.tt-dataset:not(:first-child) {
    border-top: 1px solid red;
}

.tt-suggestion img, #tab-best > div img {
    max-height: 30px;
    max-width: 20px;
    vertical-align: middle;
    padding-right: 5px;
    width: 20px;
    display: inline-block;
}

.tt-suggestion img[src=""] {
    visibility: hidden;
}

.tt-suggestion img ~ span, #tab-best > div img ~ span {
    vertical-align: middle;
}

.find_speciality:not([data-show]) {
    /*display: none;*/
}

.find_speciality, .find_serviceclass, .find_servicecategory, .find_servicetype, .find_lpu_all, .find_vrach_all, .find_town,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_all_towns, .find_lputype,
.find_subways {
    background-size: 20px !important;
    padding-left: 35px;
}

.find_speciality, .find_serviceclass, .find_servicecategory, .find_servicetype, .find_lpu_all, .find_vrach_all,
.find_manipulation, .find_drugtag, .find_all_spec, .find_all_lputypes, .find_lputype, .find_speciality {
    background: url('/static/_v1/pd/icons/plus/add-gray.png') no-repeat 10px center;
}

.find_town, .find_all_towns {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_subways {
    background: url('/static/_v1/pd/icons/pins/pin-gray.png') no-repeat 10px center;
}

.find_all {
    color: #0071FF;
    font-weight: 500;
    text-align: center;
    background-color: whitesmoke;
}

/*.find_all:hover {*/
    /*color: white;*/
    /*background-color: #0071ff;*/
/*}*/


/*.tt-suggestion.find_doctor, .tt-suggestion.find_lpu {*/
    /*padding: 3px 10px;*/
/*}*/

.vertical_tabs {
    overflow: hidden;
    border: 1px solid #ccc;
    display: table;
    width: 100%;
    border-radius: 4px;
}

.vertical_tabs > ul {
    width: 160px;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #F3F6FA;
    display: table-cell;
    vertical-align: top;
}

.vertical_tabs > ul li {
    background: none;
    width: 190px;
    margin: 0;
    padding: 0 !important;
    text-align: left;
    border-radius: 0;
    border: 0;
}

.vertical_tabs > ul li a {
    display: block;
    outline: none;
    line-height: 15px;
    padding: 10px;
}

.vertical_tabs > ul li a:hover {
    text-decoration: underline;
}

.vertical_tabs > ul li.active {
    background: #fff;
    position: relative;
    top: 0;
    border-right: none;
    border-left: none;
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    z-index: 10;
}

.vertical_tabs > ul li.active:last-child, .vertical_tabs > ul li.active:first-child {
    border-top: none;
}

.vertical_tabs > ul li.active:last-of-type {
    position: relative;
    top: 1px;
}

.vertical_tabs > ul li:first-child.selected-tab {
    border-top: none;
}

.vertical_tabs > ul li a.selected-tab {
    font-weight: 500;
    text-decoration: none;
}

.vertical_tabs .panel-container {
    padding: 20px 30px;
    background-color: white !important;
    border: none;
    margin-left: 160px;
    border-radius: 0;
    border-left: 1px solid #ccc;
    display: table-cell;
    position: relative;
    right: 1px;
    z-index: 1;
}

[data-ff-individual-services-linking-cabinet="True"] .vertical_tabs .panel-container {
    z-index: initial;
}

.vertical_tabs .panel-container > div {
    margin-top: -75px;
    padding-top: 60px;
}

#tab-best > div:first-of-type {
    margin-top: 10px;
}

#tab-best > div {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}

#tab-best > div:not(:last-of-type) {
    border-bottom: 1px solid #ccc;
}

#tab-best > div:hover {
    background-color: #eee;
}

#tab-best > div.find_doctor, #tab-best > div.find_lpu {
    padding-left: 10px;
}

#tab-best > div img {

}

#tab-best > div a {
    display: block;
    text-decoration: none;
}

#tab-best > div img ~ span.find_doctor_speclist {
    width: 500px !important;
}

#tabs_search .categories {
    width: 100%;
}

#tabs_search .categories .cnt {
    margin-left: 5px;
}

.lpu_head_ad {
    margin: 10px 0;
}

.adsbygoogle:after {
    content: "Есть противопоказания. Посоветуйтесь с врачом.";
    display: inline-block;
    text-decoration: none;
    height: 16px;
    overflow: hidden;
    padding-left: 10px;
}

.town_time {
    color: #3f86ff;
}

.phone_number_profile {
    padding-left: 5px !important;
}

.mobile_operator_img {
    max-width: 50px;
}

.patient_mobile {
    font-size: 0.9em;
}

.patient_avatar_preview {
    border: 1px solid #ccc;
}

.doctorlist_img {
    margin: 0 auto;
    float: none;
    display: block;
    width: 100%;
}

.doctor_spec_text {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px; /* Chrome, Safari, Opera */
    -moz-column-gap: 40px; /* Firefox */
    column-gap: 40px;
    margin-top: 20px;
}

.doctor_spec_text img {
    width: 100%;
    margin-bottom: 15px;
}

.doctor_spec_text h3:first-child,
.doctor_spec_text p:first-child {
    margin-top: 0;
}

.doctor_spec_text h3, .lputype_text h3 {
    display: inline-block;
    margin: 0;
}

.doctor_spec_text ul {
    margin: 5px 0;
}

.h2_rating {
    margin-left: 10px;
    margin-top: 20px;
    display: inline-block;
}

.h2_rating ~ .share_block {
    float: right;
    display: inline-block;
    margin-left: 30px;
    margin-top: 10px;
}

.h2_rating_doctorlist, .h2_rating_lpulist {
    display: block;
    margin-top: 50px;
}

.new_lpuset_lpu_name {
    width: 100%;
}

.login_frame {
    display: none;
}

/* Lpu tabs */
.lpu_tabs, .doctor_tabs {
    margin: 0 !important;
    padding: 0;
    display: table;
    width: 1000px;
}

.fixed_tabs_container ~ .panel-container #contacts,
.fixed_tabs_container ~ .panel-container #photo,
.fixed_tabs_container ~ .panel-container .doctor_rates {
    display: none;
}

.fixed_tabs_container ~ .panel-container {
    border: none;
    /*border-top: 1px solid #ccc;*/
    border-radius: 0;
    padding: 10px 0;
}

.fixed_tabs_container ~ .panel-container #rates {
    padding-top: 20px;
}

.fixed_tabs_container {

}

.fixed_tabs_container.tabs-fixed {
    top: 0;
    z-index: 99999;
    position: fixed;
    width: 100%;
    box-shadow: 4px 4px 4px #CCC;
    background-color: #eee;
}

.fixed_tabs_container .fixed_tabs > ul > .tab {
    height: 30px;
    border-radius: 0;
    display: table-cell;
    text-align: center;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    position: relative;
}

.fixed_tabs_container .fixed_tabs > ul > .tab > div {
    position: relative;
}

.tabs_cnt {
    line-height: 22px;
    position: absolute;
    color: #0091ff;
    right: 7px;
    top: 0;
}

.fixed_tabs_container .fixed_tabs > ul > .tab:not(:first-child) {
    border-left: 0;
}

.fixed_tabs_container .fixed_tabs > ul > .tab.active {
    position: static;
    border-bottom: none;
    border-top: 3px solid #0091FF;
}

.fixed_tabs_container.tabs-fixed .fixed_tabs > ul > .tab.active {
    border-top: 1px solid #ccc;
    border-bottom: 3px solid #0091FF;
}

.fixed_tabs_container .fixed_tabs > ul > .tab a {
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
}

.fixed_tabs_container ~ .panel-container .rates {
    width: 760px;
    float: left;
}

.fixed_tabs_container ~ .panel-container .moder {
    width: 540px;
}

.fixed_tabs_container ~ .panel-container .rates td:nth-child(2) {
    max-width: 600px;
}

.pseudo_h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
}

.doctor_h1 {
    max-width: 590px;
}

.doctor_h1 > span {
    line-height: 24px;
}

.pseudo_h1 div {
    font-size: 12px;
    font-weight: normal;
}

h2#phones {
    margin-top: 40px;
}

.rates_type_filter a {
    display: inline-block;
    text-decoration: none;
}

.rates_type_filter a.active {
    color: black;
    cursor: default;
}

.rates_filters {
    width: 215px;
    float: right;
    font-size: 1.2em;
}

/*.rates_filters {*/
    /*margin-top: 20px;*/
/*}*/

.rates_year_filter label {
    display: block;
}

.rates_year_filter input {
    vertical-align: bottom;
}

.filters_header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.filters_header:not(:first-child) {
    margin-top: 10px;
}

.filters_container label {
    font-size: 14px;
    max-width: 190px;
    display: inline-block;
}

.lpulist_lputype label {
    max-width: 175px;
}

.rates_year_filter a.checked ~ label,
.rates_type_filter a.checked ~ label {
    font-weight: 500;
    color: #0091ff;
}

.filters_container {
    border: 1px solid #ccc;
    padding: 0;
    border-radius: 4px;
    webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)
}

.filters_container > div:not(:first-child) {
    border-top: 1px solid #ccc;
}

.filters_container > div {
    margin: 0 10px;
    padding: 10px 0;
}

.hidden {
    display: none;
}

.lpu_set {
    margin-top: 15px;
}

.lpu_set a {
    color: #0091FF;
    font-weight: 500;
    text-decoration: none;
    margin-left: 10px;
}

a.show_all_button {
    display: inline-block;
    font-size: 18px;
    color: #0091ff;
    border: 1px solid #0091ff;
    width: 300px;
    font-weight: normal;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    text-decoration: none;
    /*margin: 10px 0 40px 0;*/
    margin-right: 160px;
}

a.show_all_button:hover {
    color: #0071ff;
    border: 1px solid #0071ff;
    background-color: #fafafa;
}

.doctor_table_description{
    border-spacing: 4px;
    padding-left: 10px;
}

.doctor_table_description tr {
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
}

.doctor_table_description_title{
    padding-left: 10px;
}

.doctor_education, .doctor_courses:not(.doctor_table_description) {
    padding-left: 0;
}

.doctor_education li, .doctor_courses li {
    list-style: none;
}

.doctor_education li > span, .doctor_courses li > span {
    margin-left: 10px;
}

.doctor_education_block h3 {
    margin-bottom: 5px;
}

.doctor_number_td {
    text-align: right;
    vertical-align: top;
    width: 1px;
}

.doctor_experience_td {
    vertical-align: top;
}

.doctor_year_td {
    vertical-align: top;
}

.doctor_qualifications_block h3, .doctor_honors_block h3 {
    margin-bottom: 5px;
}

.doctor_medicial_assotiation h3 {
    margin-bottom: 5px;
}

.doctor_td_name {
    vertical-align: top;
    display: inline-block;
    width: 430px;
    overflow: hidden;
}

.doctor_other_blocks h3 {
    margin-bottom: 5px;
}

.doctor_medicial_ul {
    margin-top: 7px;
}

#main .rates > tbody > tr:first-child > td,
#rates .rates > tbody > tr:first-child > td {
    border: 0;
}

.rates td.border_top {
    padding-bottom: 5px;
}

.gallery_container > .block {
    overflow: hidden;
}

/* Ratereply */

.ratereply_img {
    max-height: 50px;
    max-width: 50px;
}

.ratereply_img_container {
    margin-left: 45px;
    width: 50px;
    height: 50px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.ratereply_img_container, .moder {
    margin-top: 5px;
}

.town_vrach_speclist ul {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    padding-left: 0;
}

.town_vrach_speclist li {
    list-style: none;
    padding-left: 20px;
    font-size: 13px;
    line-height: 18px;
}

.town_vrach_speclist_title {
    font-weight: 500;
    padding-left: 0 !important;
    margin-bottom: 5px;
}

.town_vrach_speclist_title:not(:first-child) {
    margin-top: 10px;
}

.town_vrach_speclist a {
    text-decoration: none !important;
}

.pd_menu_item {
    padding-top: 5px;
    box-sizing: border-box;
}

.pd_menu_item a {
    display: inline-block !important;
    padding-right: 45px !important;
    background: none !important;
    border: none !important;
    color: black !important;
}

.pd_menu_item .cnt {
    position: relative;
    right: 45px;
    display: inline-block;
    width: 0;
}

.pd_menu_item.current {
    border: 1px solid #ccc !important;
    height: 37px;
    background: whitesmoke;
    border-bottom: none !important;
    border-radius: 4px 4px 0 0;
}

.doctorlist_filters {
    width: 240px;
    float: right;
    margin-top: 0 !important;
    margin-bottom: 10px;
}

.ajax_overlay {
    display: none;
    background-color: white;
}

.ajax_overlay.active {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1000;
    width: 100%;
    opacity: 0.35;
}

.town_vrach_head {
    width: 700px;
    float: left;
}

.lpuset_rate_address, .doctor_rate_address {
    display: inline-block;
    margin-left: 30px !important;
}

.doctor_rate_address {
    display: block;
    margin-left: 45px !important;
    margin-bottom: 10px;
}

.lpuset_rate_address_shift {
    position: relative;
    top: 7px;
}

.sticky_top {
    display: block !important;
}

.blue_button {
    width: auto;
    height: auto;
    vertical-align: middle;
    background-color: #0091ff;
    color: white;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 9px 38px;
    text-decoration: none;
    border: none;
    margin-bottom: 5px;
}

.blue_button:hover {
    background: #0081e2 !important;
}


.white_button {
    width: auto;
    height: auto;
    display: inline-block;
    font-size: 14px;
    color: #0091ff;
    border-radius: 10px;
    border: 1px solid #0091ff;
    font-weight: 500;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: center;
    line-height: 37px;
    text-decoration: none;
    margin: 10px 0 40px 0;
}

.white_button:hover {
    color: #0071ff;
    border-color: #0071ff;
    background-color: #fafafa;
}

.ask_doctor_text {
    padding: 18px;
    height: 260px;
    box-sizing: border-box
}

.ask_doctor_text p {
    margin: 10px 0;
}

.ask_doctor_patient_poll ul {
    list-style: none;
    padding-left: 0;
}

.ask_doctor_pay, .ask_doctor_pay2 {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.view_as_link {
    background: none;
    background-color: transparent;
    color: black;
    border: none;
    padding: 0;
    display: inline;
    text-decoration: underline;
    outline: none;
}

.view_as_link:hover {
    background: none;
    background-color: transparent;
    color: red;
    cursor: pointer;
}

.link_to_profile_registration_step_two .green_button {
    padding: 2px 12px;
    height: 24px;
    outline: none;
}

.link_to_profile_registration_step_two .green_button::-moz-focus-inner {
  border: 0;
}

.rate_doc_preview {
    max-width: 100px;
}

.find_drug .rates_count {
    color: #999;
    float: right;
    vertical-align: middle;
    margin-top: 3px;
}

.merge_accounts_dialog {
    text-align: center;
    padding: 10px;
}

.merge_accounts_dialog img {
    vertical-align: middle;
    margin: 10px 5px;
}

.rate_moderation_bg {
    background-color: #ccffff;
}

.rate_declined_bg {
    background-color: #ff9999;
}

.rate_on_check_bg {
    background-color: #ffffaa;
}

.rate_correct_bg {
    background-color: #ff7654;
}

.rate_status_history {
    background: url("/static/_v1/pd/icons/search/search-eye.png") left center no-repeat;
    padding-left: 30px;
    height: 24px;
    line-height: 18px;
    width: 100px;
    position: absolute;
    top: 0;
    right: 250px;
}

.rate_status_history > span {
    border-bottom: 1px dotted black;
    cursor: pointer;
}

.rate_status_history_lpuset {
    top: -7px;
}

.rates tr > td {
    position: relative;
}

.tooltip_templates {
    display: none;
}

.display_inline{
    display: inline-block;
}

.text_shares_article {
    font-size: 14px;
    line-height: 1.5;
}

.text_shares_article ol {
    margin-top: 0;
}

a.help_tooltipster {
    display: inline-block;
    width: 24px;
    background: url("/static/_v1/pd/icons/tooltipster-question-mark.png") no-repeat scroll center bottom;
    height: 13px;
    position: relative;
    top: 1px;
}

.add-balans__btn {
    display: block !important;
    height: 36px;
    width: 80% !important;
    padding-top: 10px !important;
    background-color: #00c000;
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    color: white !important;
}

.add-balans__btn:hover {
     background-color: #0c0;
}

.list_menu_padding {
    padding-left: 10px;
    font-size: 14px
}

.prof_menu > ul > li > a {
    padding-left: 20px !important;
}

.div_history_hidden {
    display: none;
}

.div_history {
    margin-top: -6px;
    margin-left: 10px;
}

.purchase_history {
    color:         #8f9392;
    position:      absolute;
    left:          296px;
    top:           -17px;
    display:       inline-block;
    width:         80px;
    font-size: 14px;
    margin-bottom: -10px;
    cursor: pointer;
}

.purchase_history_conditions{
    left: 319px;
}

.purchase_history_advcontract {
    left: 420px;
}

.purchase_history_licence {
    left: 320px;
}

.purchase_history_bg {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 0;
    width: 13px;
    height: 13px;
    background: #FFF url(/static/_v1/pd/icons/arrows/drop-down-menu.png) no-repeat center;

}

.purchase_history_bg_rigth {
    transform: rotate(-90deg);
}


.mainpage_million_rates > div {
    width: 180px;
    position: relative;
    bottom: 30px;
    margin-bottom: 30px;
}

.info-wrapp {
    margin-bottom: 10px !important;
}

.text-wrapp {
    position: relative;
    width: 150px;
    padding: 5px 5px 5px 60px;
}

.main-icon {
    position: absolute;
    top: 0;
    left: 10px;
    width: 40px;
    margin: 0 !important;
}

.main-icon-calendar {
    top: 10px;
    width: 43px;
}

.mainpage_million_rates > div:first-child {
    width: 770px;
    top: 45px;
}

.mainpage_million_rates span.rates_count {
    font-size: 86px;
    color: #333;
}

.mainpage_million_rates .rates_text {
    font-size: 53px;
    margin-left: 10px;
}

.mainpage_million_rates .main_rates_contaner > img {
    width: 200px;
}

.mainpage_million_rates .main_rates_contaner {
    width: 770px;
    margin-bottom: 150px;
    margin-top: -40px;
}

.mainpage_million_rates .main_rates_contaner > div {
    top: 61px;
    margin-left: 280px;
}

.page_warning_text {
    color: red;
    margin-bottom: 20px;
    font-size: 1.7em;
    line-height: 1.5em;
    border-top: 5px solid red;
    border-bottom: 5px solid red;
}
