/**
 * @deprecated
 * Стили перенесены сюда
 * из шаблона consult/templates/consult.html
 * в связи с удалением assets.
 */

/* stylelint-disable */

#id_age {
    width: 40px;
}

#id_spec_group {
    width: 110px;
}

#id_gender {
    width: 85px;
}

#add_image {
    padding: 2px;
}

#add_image:disabled {
    opacity: 0.5;
}

#add_image:disabled:hover {
    background-color: #f7f7f7;
}

.delete_image {
    border-bottom: none;
    position: relative;
    top: 3px;
}

.time_left, .now {
    font-size: 0.9em;
    font-weight: 400;
}

.consult_top > div:first-child {
    margin-left: 3px;
}

.consult_top > div {
    border:1px solid #dddddd;
    height: 90px;
    width:180px;
    border-radius:5px;
    float: left;
    margin-right: 7px;
    padding: 5px;
    position: relative;
    background-color: white;
}

.consult_top .avatar {
    margin-right: 3px;
}

.consult_top_position {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 46px;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    padding: 5px 3px 3px 14px;
    font-size: 1.5em;
    background-color: #CCE9FF;
}

.image_list > div {
    float: left;
    margin-right: 5px;
}

.question_buttons {
    float: right;
    width: 100%;
}

.question_button:disabled {
    color: white;
}

.question_button:hover {
    background-color: #0081e2;
}

#add_image {
    width: 100px;
    height: 100px;
    float: left;
    background-image: url('/static/_v1/mo/icons/photo-camera.png');
    background-repeat: no-repeat;
    background-position: center 16px;
    padding-top: 40px;
}

#image_placeholder {
    background-image: url('/static/_v1/mo/gif/small-common-preloader.gif');
    background-size: 16px;
    background-position: center;
}

.image_block {
    float: left;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 0;
    margin-right: 10px;
}

.image_block div {
    margin-top: 40px;
    background-color: white;
}

#uploaded_images {
    overflow: hidden;
    margin-bottom: 5px;
}

.delete_image {
    float: right;
    cursor: pointer;
}

#id_text {
    width: 100%;
    display: block;
    height: 445px;
    max-width: 733px;
}

#id_spec_group, #id_email {
    width: 100%;
}

.new_question ol {
    margin: 0;
}

label {
    font-weight: 500;
}

.error_left {
    background-color:red;
    border: 1px solid #da0000;
    color: white;
    padding: 4px;
    width: auto;
    display: inline-block;
}

.show_question_form {
    margin-bottom: 20px;
    margin-right: 20px;
}

.new_question {
    margin-bottom: 20px;
    margin-top: 20px;
}

.subjects_link {
    background-color: white;
    color: #0091ff;
    border: 1px solid #0091ff;
}

.subjects_link:hover:enabled {
    background-color: #0081e2;
    color: white;
}

.pager.block {
    width: auto;
}

.consult_top_link {
    display: inline-block;
    border: 2px solid #0091ff;
    border-radius: 10px;
    padding: 13px 26px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #0091ff;
    text-decoration: none;
}

.consult_top_link:hover {
    border-color: #0081e2;
    color: #0081e2;
}
