@charset "utf-8";

#logo {
    background: 0 0 no-repeat;
}

.new_question, .new_answer {
    border-radius: 4px;
    overflow: hidden;
    padding: 10px 20px;
}

.new_question, .new_answer {
    background-color: #cce9ff;
    border: 1px solid lightgrey;
}

.question_buttons {
    /*width: 89%;*/
    /*margin-bottom: 20px;*/
    overflow: hidden;
}

::-moz-focus-inner {border:0;}

.question_button, .answer_button {
    background-color: #0091ff;
    border: 0;
    border-radius: 10px;
    outline: none;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    height: 45px;
    width: 240px;
    color: white;
}

.question_button:hover:enabled, .answer_button:hover:enabled {
    background-color: #0081e2;
}

.answer_button:disabled {
    background-color: #eee;
    cursor: default;
}

.anonym_question_button {
    margin-bottom: 5px;
}

.close_question {
    margin-left: 236px;
}

.answer_container {
    padding: 10px 0px;
    overflow: hidden;
}

td.awards {
    height: 10px;
    position: relative;
}

div.awards {
    position: absolute;
    bottom: -5px;
    left: 45px;
    height: 130% !important;
    width: 50% !important;
    margin: auto;
    border: 1px solid #f4f400;
    background-color: #fff;
    vertical-align: middle;
    text-align: center;
}

.awards_in {
    display: inline-block;
    height: 75%;
}

.answer {
    border-spacing: 0;
    float: right;
    margin-top: 0;
    position: relative;
    border: 1px solid #aaa;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
}

.answer:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #aaa;
    display: block;
    width: 0;
    z-index: 0;
    left: -15px;
    top: 10px
}

.answer:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #fff;
    display: block;
    width: 0;
    z-index: 1;
    left: -14px;
    top: 10px;
}

.question_container {
    overflow: hidden;
    padding: 5px 0px;
}

.question {
    border-spacing: 0;
    /*width: 88%;*/
    /*float: right;*/
    margin-bottom: 10px;
}

.question tr:first-child td {
    width: 25%;
}

.question tr:first-child td:last-child {
    width: 14% !important;
}

.question td, .answer td {
    padding: 5px;
}

.question_header td:not(:first-child), .answer_header td:last-child {
    text-align: right;
}

td.photo {
    text-align: center !important;
}

.answer tr:first-child td {
    border-top: 1px solid #f4f400;
    border-bottom: 1px solid #f4f400;
}

.answer tr:first-child td:last-child {
    border-right: 1px solid #f4f400;
}

.answer tr:first-child td:first-child {
    border-left: 1px solid #f4f400;
}

.question.opened tr:first-child td {
    border-top: 1px solid #97db97;
    border-bottom: 1px solid #97db97;
}

.question.opened tr:first-child td:last-child {
    border-right: 1px solid #97db97;
}

.question.opened tr:first-child td:first-child {
    border-left: 1px solid #97db97;
}

.question.opened .num_answers_consult {
    border-radius: 4px;
    border: 1px solid #97db97;
}

.question.closed tr:first-child td {
    border-top: 1px solid #9ee6e3;
    border-bottom: 1px solid #9ee6e3;
}

.question.closed tr:first-child td:last-child {
    border-right: 1px solid #9ee6e3;
}

.question.closed tr:first-child td:first-child {
    border-left: 1px solid #9ee6e3;
}

.question.closed .num_answers_consult {
    border-radius: 4px;
    border: 1px solid #9ee6e3;
}

.question tr:first-child td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.question tr:first-child td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.answer tr:first-child td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.answer tr:first-child td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.question.opened tr:first-child td, .question.opened .num_answers_consult_green {
    background-color: #A9F5A9;
}

.question.closed tr:first-child td, .question.closed .num_answers_consult_green {
    background-color: #A9F5F2;
}

.answer tr:first-child td {
    background-color: #ffff00;
}

.answer tr:last-child {
    border-top: none;
}

.answer tr:nth-child(2) {
    border-bottom: none;
}

.answer tr:first-child td {
    width: 33%;
}

.answer tr:first-child td:not(:first-child), .question tr:first-child td:not(:first-child) {
    font-size: 0.9em;
}

.question_link:hover {
    cursor: pointer;
}

.question_link:hover .question_text {
    background-color: #f5f5f5;
}

.question_link:hover .my_question {
    /*background-color: rgba(245, 247, 175, 0.75) !important;*/
}

.answer_inner, .question_inner {
    overflow: hidden;
}

.question img.avatar, .new_answer img.avatar {
    display: inline-block;
    max-height: 50px;
    max-width: 50px;
    vertical-align: middle;
}

.doctor_info img.avatar {
    width: 75px;
    border-radius: 5px;
    object-fit: cover;
    height: 75px;
}

.appointment_status {
    width: 8px;
    height: 8px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
}

.appoinment_on {
    background-color: green;
    border-color: darkgreen;
}

.appoinment_off {
    background-color: red;
    border-color: darkred;
}

.answer a, .question a, .new_question a:not(.rules_link) {
    border-bottom: none;
    text-decoration: none;
}

.new_question .attached_images {
    padding-left: 5px;
}

.new_question .chars_left, .new_answer .chars_left {

}

.new_question .error_left, .new_answer .error_left, .limit_exceeded {
    /*padding-left: 20px;*/
    font-weight: 500;
}

textarea#id_text {
    vertical-align: top;
}

#id_region {
    width: 100%;
}

.question_footer {
    font-size: 0.8em;
}

/*.question_info {*/
/*float: right;*/
/*text-align: right;*/
/*}*/

.new_question_title {
    font-size: 1.2em;
    font-weight: 500;
}

.question_title, .answer_title {
    font-size: 1.2em;
    font-weight: 500;
    padding-left: 10px !important;
}

.question_description {
    font-size: 0.9em;
    font-style: italic;
    float: right;
}

.question_title:hover {
    border-bottom: none;
    text-decoration: none;
}

.question_buttons {
    text-align: right;
}

.answer_button {
    height: 50px;
    padding: 5px;
    width: 130px;
}

.answer_button {
    float: right !important;
}

.new_question td {
    padding-right: 5px;
    text-align: left;
    height: 26px;
}

.grey_text {
    color: #ccc;
}

.question_search {
    display: inline-block;
}

.question_search button {
    padding: 3px;
}

.question_by_number {
    float: right;
}

.q_image {
    border-bottom: none;
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
}

.q_image img {
    max-height: 150px;
    max-width: 100px;
}

.user_info, .doctor_info {
    word-break: break-all;
}

.user_info {
    width: 11%;
    float: right;
    padding-left: 5px;
    text-align: center;
}

.doctor_info a {
    font-size: 1.2em;
    font-weight: 500;
}

.user_info a {
    text-decoration: none;
}

.doctor_info {
    width: auto;
    margin-left: 20px;
    padding: 5px;
    padding-top: 0;
    text-align: center;
    display: table-cell;
    white-space: nowrap;
    vertical-align: top;
    min-width: 145px;
}

.answer p, .question p {
    font-size: 1.1em;
    margin-top: 0 !important;
    overflow: hidden;
}

.answer p {
    /*width: 570px;*/
}

.num_answers {
    color: #f00;
    float: right;
    font-size: 1.2em;
    position: relative;
    top: 5px;
}

.patient_age, .doctor_spec {
    font-size: 0.9em;
}

.img_number {
    background-color: #ffffff;
    border: 2px solid grey;
    border-radius: 15px;
    bottom: 10px;
    height: 10px;
    padding: 2px 6px;
    position: relative;
    right: 10px;
    width: 10px;
}

button.useful {
    padding: 5px !important;
    border: 1px solid #999;
    border-radius: 4px !important;
    font-weight: 500;
    font-size: 1.2em;
    height: 27px !important;
    margin-left: 10px;
}

button.useful {
    background-color: #0091ff;
    color: white;
    float: right;
}

button.useful:hover {
    background-color: #0081e2;
}

.tags {
    background-color: #fff;
    text-align: left;
    font-size: 0.8em;
    padding: 0px !important;
}

.tags div {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
}

img.gender {
    vertical-align: middle;
}

.num_answers_consult {
    font-weight: 500;
    text-align: center;
}

.num_answers_red {
    background-color: red;
    border: 1px solid #e90000 !important;
    color: #fff !important;
}

.question_text, .answer_text {
    padding: 5px 15px !important;
}

img.transparent {
    opacity: 0.1;
    filter: alpha(opacity=10);
}

div.ui-tooltip.howto_tooltip_positioner {
    min-width: 600px !important;
    font-size: 1em;
}

.howto img {
    vertical-align: middle;
    margin-bottom: 3px;
}

.my_question {
    /*background-color: rgba(253, 255, 181, 0.73) !important;*/
}

.error_border {
    border: 1px solid red;
}

table.consult_top {
    border-collapse: collapse;
    width: 100%;
}
table.consult_top td,
table.consult_top th {
    border: 1px solid #ccc;
    padding: 9px 18px;
    font-size: 14px;
}
table.consult_top th {
    background-color: whitesmoke;
    font-weight: normal;
}
table.consult_top td.column_edit_button {
    font-size: 12px;
    width: 47px;
}

.consult_top_all {
    text-align: right;
    font-size: 1.3em;
    height: 0;
    position: relative;
    bottom: 24px;
    right: 10px;
}

.new_question > div:first-child {
    width: 840px;
    float: left;
}

.consult_top_mesto, .consult_top_rating {
    text-align: center;
}

.new_question .upload_status {
    position: absolute;
}

#image_title_dialog img {
    margin: auto auto;
    max-width: 150px;
    max-height: 150px;
    display: block;
}

#image_title_dialog #image_title_input {
    width: 100%;
    box-shadow: 0 0 10px #0091ff;
    margin-top: 10px;
}

.image_title_dialog button.ui-state-default.send {
    background: none;
    background-color: #0091ff;
    color: white;
    font-weight: 500;
}

.image_title_dialog button.ui-state-default.send:hover {
    background-color: #0081e2;
}

.image_title_dialog .ui-dialog-titlebar {
    display: none;
}

h1.inline_header {
    display: inline;
}

#my_questions_button {
    color: #0091ff;
}

#my_questions_button:hover {
    color: #0081e2;
}

.email_not_confirmed_message {
    background: #ffd;
    border: 1px #ddd solid;
    border-left: 0;
    border-right: 0;
    color: #333;
    padding: 5px;
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
}

#delete_draft {
    float: right;
    vertical-align: bottom;
    margin-top: 7px;
    margin-left: 4px;
    cursor: pointer;
}

.left_panel {
    display: table-cell;
    white-space: nowrap;
    vertical-align: top;
    padding-right: 10px;
    width: 1px;
}
.right_panel {
    display: table-cell;
    width: 862px;
}

.left_panel ul.specialities {
    padding-left: 0;
}

.left_panel ul.specialities li {
    list-style: none;
    line-height: 25px;
    padding-left: 0;
}

.answer_wrapper {
    display: table-cell;
    padding-left: 20px;
    width: 1000px;
}

.view_as_link {
    background: none;
    background-color: transparent;
    color: black;
    border: none;
    padding: 0;
    display: inline;
    text-decoration: underline;
    outline: none;
}

.view_as_link:hover {
    background: none;
    background-color: transparent;
    color: red;
    cursor: pointer;
}

#nav2 > .view_consult_as_patient_menu_item > a#view_consult_as_patient {
    background: none;
    background-color: transparent;
    color: black;
    border: none;
    padding: 0;
    display: inline;
    text-decoration: underline;
    outline: none;
    font-weight: normal;
    font-size: 12px;
}

#nav2 > .view_consult_as_patient_menu_item > a#view_consult_as_patient:hover {
    background: none;
    background-color: transparent;
    color: red;
    cursor: pointer;
}

#nav2 > .view_consult_as_patient_menu_item {
    overflow: hidden;
    float: right;
}

#nav2 > li.pharm_search {
    float: right;
}



.consult_search,
#nav2 > li.consult_search {
    float: right;
}

.consult_search .search_button {
    background-color: #0091ff;
    color: white;
    font-weight: 500;
    height: 25px !important;
    border-width: 0;
    z-index: 3;
    vertical-align: top;
    margin: 0 0 0 3px;
    width: 55px;
}

.left_panel li.active a {
    color: red;
}

